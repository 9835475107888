import { useQuery } from 'react-query';
import axios, { AxiosError, RawAxiosRequestHeaders } from 'axios';
import { NavLink, Navigate } from 'react-router-dom';
import { IAdvertisement } from 'Advertisements/IAdvertisement';
import { debounce } from 'lodash';
import { useAuth0 } from '@auth0/auth0-react';
import { ClipLoader } from 'react-spinners';
import { useState } from 'react';
import SpinnerModal from 'Components/SpinnerModal';
import { IImage } from 'Admin/Images/IImage';

const returnHome = (
  <NavLink to="/Admin/Advertisements">
    Propagandas
  </NavLink>
)

const pageStyle: React.CSSProperties = {
  textAlign: 'left'
}

const gridStyle: React.CSSProperties = {
  display: 'grid',
  gridTemplateColumns: '200px auto',
  maxWidth: '1000px',
  columnGap: '1em',
  rowGap: '.5em',
  fontSize: '1.5em'
}

const advertisementPhotoDivStyle: React.CSSProperties = {
  maxWidth: '50px'
}

const advertisementPhotoStyle: React.CSSProperties = {
  maxWidth: '100%'
}

function NewAdvertisementAdminPage() {
  const { user, getAccessTokenSilently, isLoading: auth0Loading } = useAuth0();
  const initialValues: IAdvertisement = {
    id: '',
    companyName: '',
    adPhotoURL: '',
    linkURL: ''
  }
  const [values, setValues] = useState(initialValues);
  const [saving, setSaving] = useState(false);
  const [adPhotos, setAdPhotos] = useState<{ display: string, value: string, imageType?: string }[]>([]);
  const [spinnerDialogText, setSpinnerDialogText] = useState("");



  const getImages = async () => {
    let header: RawAxiosRequestHeaders = {
      'Content-Type': 'application/json'
    }
    const accessToken = await getAccessTokenSilently();
    header.Authorization = `Bearer ${accessToken}`;
    const imageUrl = `${process.env.REACT_APP_BACKEND_URL}images`;
    console.log('imageUrl', imageUrl)
    const data = await (await axios.get<any>(imageUrl, {
      headers: header
    })).data
    console.log('image data', data)
    const mappedImages: { display: string, value: string, imageType?: string }[] =
      data.images.filter((x: IImage) => !x.hidden).map((x: IImage) => {
        return {
          display: x.name,
          value: x.imageUrl,
          imageType: x.imageType
        }
      })

    const adPhotos = mappedImages.filter(x => x.imageType === 'adPhoto')
    setAdPhotos(adPhotos);
    return data;
  }

  const handleChange = (e: (React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>)) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value
    })
  }

  const isFormValid = () => {
    return (values.adPhotoURL.length > 0 && values.companyName.length > 0 && values.linkURL.length > 0);
  }

  const handleBlur = (e: (React.FocusEvent<HTMLInputElement>)) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value
    })
  }

  const debouncedHandler = debounce(handleChange, 500);

  const save = async () => {
    setSpinnerDialogText("Salvando propaganda")
    const advertisementURL = `${process.env.REACT_APP_BACKEND_URL}advertisements/`;
    setSaving(true)
    const accessToken = await getAccessTokenSilently();
    let header: RawAxiosRequestHeaders = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
    const postBody: IAdvertisement = values;
    axios.post(advertisementURL, postBody, {
      headers: header
    }).then(res => {
      console.log(res)
      alert('save successful')
    }).catch((err: AxiosError | Error) => {
      if (axios.isAxiosError(err)) {
        console.error(err.response?.data);
      }
      alert('save failed')
    }).finally(() => {
      setSaving(false)
    })
  }

  const { status, data, error } = useQuery('advertisementPhotos', getImages, {
    enabled: (!auth0Loading && user?.['peloestudo/roles'].includes('Owner'))
  })
  if (user && !user['peloestudo/roles'].includes('Owner')) {
    return (<Navigate to="/" />)
  }

  if (status === 'loading') {
    return <div><ClipLoader /></div>
  }

  if (status === 'error') {
    console.log(error)
    return <div>Error loading Images</div>
  }

  if (data !== undefined) {
    return (
      <section style={pageStyle}>
        <h2>Administrar Propaganda</h2>
        <div style={gridStyle}>
          <label>Nome da Empresa:
          </label>
          <input
            type="text"
            name="companyName"
            onChange={debouncedHandler}
            onBlur={handleBlur}
            defaultValue={values.companyName}
          />
          {adPhotos.length > 0 &&
            <><label>Foto da Propaganda</label>
              <div>
                {values.adPhotoURL &&
                  <div style={advertisementPhotoDivStyle}>
                    <img src={values.adPhotoURL} alt={`${values.adPhotoURL}`} style={advertisementPhotoStyle} />
                  </div>
                }
                <select value={values.adPhotoURL} name="adPhotoURL" onChange={handleChange}>
                  <option></option>
                  {adPhotos.map(x => {
                    return <option value={x.value}>{x.display}</option>
                  })}
                </select>
              </div></>
          }
          <label>Link da Propaganda</label>
          <input
            type="text"
            name="linkURL"
            onChange={debouncedHandler}
            onBlur={handleBlur}
            defaultValue={values.linkURL}
          />
        </div>
        <div>
          <button onClick={save} disabled={!isFormValid() || saving}>Submit</button>
        </div>

        {returnHome}
        {
          saving &&
          <SpinnerModal
            spinnerText={spinnerDialogText}
          />
        }
      </section >
    )
  }
  return <></>
}

export default NewAdvertisementAdminPage;