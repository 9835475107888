import { Editor } from '@tinymce/tinymce-react';
import { Editor as TinyMCEEditor } from 'tinymce';
import { IComment } from "./IComment";
import { useState, useRef } from "react";
import axios, { AxiosError, RawAxiosRequestHeaders } from 'axios';
import { useAuth0 } from "@auth0/auth0-react";
import SpinnerModal from 'Components/SpinnerModal';

interface NewCommentProperties {
  articleId: string,
  parentId?: string
}


function NewComment({ articleId, parentId }: NewCommentProperties) {
  const { user, getAccessTokenSilently } = useAuth0();
  const initialValues: IComment = {
    content: '',
    user: user?.name || '',
    articleId: articleId
  }

  if (parentId) {
    initialValues.parent = parentId
  }

  const [values, setValues] = useState(initialValues);
  const [saving, setSaving] = useState(false);
  const [hasSaved, setHasSaved] = useState(false);
  const editorRef = useRef<TinyMCEEditor | null>(null)

  const saveComment = async () => {
    const commentURL = `${process.env.REACT_APP_BACKEND_URL}comments`;
    setSaving(true)
    const accessToken = await getAccessTokenSilently();
    let header: RawAxiosRequestHeaders = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
    const postBody: IComment = Object.assign({}, values);
    axios.post(commentURL, postBody, {
      headers: header
    }).then(res => {
      setHasSaved(true);
      alert('save successful')
    }).catch((err: AxiosError | Error) => {
      if (axios.isAxiosError(err)) {
        console.error(err.response?.data);
      }
      alert('save failed')
    }).finally(() => {
      setSaving(false)
    })
  }
  const isFormValid = () => {
    return (values.content?.length > 0)
  }
  const handleEditorChange = (newValue: string) => {
    setValues({
      ...values,
      content: newValue
    })
  }

  return (
    <div>
      <Editor
        apiKey='c27sqmj1ce4fnrsz5n0bcciljvxysmhsu5mjm3jzxdr24amf'
        onInit={(evt, editor) => editorRef.current = editor}
        onEditorChange={(newValue, editor) => handleEditorChange(newValue)}
        value={values.content}
        init={{
          height: 500,
          plugins: 'preview code',
          toolbar: [
            { name: 'history', items: ['undo', 'redo'] },
            { name: 'styles', items: ['styles'] },
            { name: 'formatting', items: ['bold', 'italic', 'blockquote'] },
            { name: 'alignment', items: ['alignleft', 'aligncenter', 'alignright', 'alignjustify', 'alignnone'] },
            { name: 'indentation', items: ['outdent', 'indent'] },
            { name: 'preview', items: ['preview', 'code'] }
          ]
        }}
      />
      <button onClick={saveComment} disabled={!isFormValid() || saving || hasSaved} >Salvar coment&aacute;rio</button>
      {
        saving &&
        <SpinnerModal
          spinnerText="Salvando Comentário"
        />
      }
    </div>
  )
}

export default NewComment;