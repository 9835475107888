import { useQueries, QueryClient } from 'react-query';
import axios, { AxiosError, RawAxiosRequestHeaders } from 'axios';
import { useNavigate, useParams, NavLink, Navigate } from 'react-router-dom';
import { IArticle } from './IArticle';
import { Editor } from '@tinymce/tinymce-react';
import { Editor as TinyMCEEditor } from 'tinymce';
import { debounce } from 'lodash';
import { useAuth0 } from '@auth0/auth0-react';
import { ClipLoader } from 'react-spinners';
import { useRef, useState } from 'react';
import SpinnerModal from 'Components/SpinnerModal';
import { IImage } from 'Admin/Images/IImage';

const returnHome = (
  <NavLink to="/Articles">
    Articles
  </NavLink>
)

const queryClient = new QueryClient()

const pageStyle: React.CSSProperties = {
  textAlign: 'left'
}

const buttonDiv: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between'
}

const gridStyle: React.CSSProperties = {
  display: 'grid',
  gridTemplateColumns: '200px auto',
  maxWidth: '1000px',
  columnGap: '1em',
  rowGap: '.5em',
  fontSize: '1.5em'
}

const authorPhotoDivStyle: React.CSSProperties = {
  maxWidth: '50px'
}

const authorPhotoStyle: React.CSSProperties = {
  maxWidth: '100%'
}

const headerImageStyle: React.CSSProperties = {
  maxWidth: '100px',
  width: '100%'
}

function EditArticle() {
  const navigate = useNavigate();
  let { id } = useParams()
  const { user, getAccessTokenSilently, isLoading: auth0Loading } = useAuth0();
  const initialValues: IArticle = {
    title: '',
    author: user?.name || '',
    content: '',
    header: ''
  }
  const [values, setValues] = useState(initialValues);
  const [saving, setSaving] = useState(false);
  const editorRef = useRef<TinyMCEEditor | null>(null)
  const [authorPhotos, setAuthorPhotos] = useState<{ display: string, value: string, imageType?: string }[]>([]);
  const [articleImages, setArticleImages] = useState<{ display: string, value: string, imageType?: string }[]>([]);
  const [initialArticle, setInitialArticle] = useState(initialValues)
  const [spinnerDialogText, setSpinnerDialogText] = useState("");

  const loadArticle = async () => {
    let header: RawAxiosRequestHeaders = {
      'Content-Type': 'application/json'
    }
    const accessToken = await getAccessTokenSilently();
    header.Authorization = `Bearer ${accessToken}`;
    const articleURL = `${process.env.REACT_APP_BACKEND_URL}articles/${id}`;
    const data = await (await axios.get<any>(articleURL, {
      headers: header
    })).data
    const article: IArticle = {
      title: data.article.title,
      approved: data.article.approved,
      content: data.article.content,
      dateSubmitted: new Date(data.article.dateSubmitted),
      author: data.article.author,
      header: data.article.header,
      authorPhoto: data.article.authorPhoto,
      articleImage: data.article.articleImage
    }
    setValues(article)
    setInitialArticle(article)

    if (authorPhotos.length > 0 && article.authorPhoto) {
      if (!authorPhotos.some(x => x.value === article.authorPhoto)) {
        authorPhotos.push({ value: article.authorPhoto, display: article.authorPhoto })
        setAuthorPhotos(authorPhotos);
      }
    }

    if (articleImages.length > 0 && article.articleImage) {
      if (!articleImages.some(x => x.value === article.articleImage)) {
        articleImages.push({ value: article.articleImage, display: article.articleImage })
      }
      setArticleImages(articleImages)
    }


    return article
  }

  const getImages = async () => {
    let header: RawAxiosRequestHeaders = {
      'Content-Type': 'application/json'
    }
    const accessToken = await getAccessTokenSilently();
    header.Authorization = `Bearer ${accessToken}`;
    const imageUrl = `${process.env.REACT_APP_BACKEND_URL}images`;
    const data = await (await axios.get<any>(imageUrl, {
      headers: header
    })).data
    const mappedImages: { display: string, value: string, imageType?: string }[] =
      data.images.filter((x: IImage) => !x.hidden).map((x: IImage) => {
        return {
          display: x.name,
          value: x.imageUrl,
          imageType: x.imageType
        }
      })

    const authorPics = mappedImages.filter(x => x.imageType === 'userPhoto')
    if (values.authorPhoto && !authorPics.some(x => x.value === values.authorPhoto)) {
      authorPics.push({ value: values.authorPhoto, display: values.authorPhoto })
    }
    setAuthorPhotos(authorPics);
    const articlePics = mappedImages.filter(x => x.imageType === 'articlePhoto')
    if (values.articleImage && !articlePics.some(x => x.value === values.articleImage)) {
      articlePics.push({ value: values.articleImage, display: values.articleImage })
    }
    setArticleImages(articlePics)
    return data;
  }

  const handleChange = (e: (React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement> | React.ChangeEvent<HTMLSelectElement>)) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value
    })
  }

  const handleApproveChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      approved: !values.approved
    })
  }

  const handleEditorChange = (newValue: string) => {
    setValues({
      ...values,
      content: newValue
    })
  }

  const isFormValid = () => {
    return (values.title.length > 0 && values.author.length > 0 && values.content.length > 0 && values.header?.length > 0 && values !== initialArticle)
  }
  const handleBlur = (e: (React.FocusEvent<HTMLInputElement> | React.FocusEvent<HTMLTextAreaElement>)) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value
    })
  }
  const debouncedHandler = debounce(handleChange, 500);

  const save = async () => {
    setSpinnerDialogText("Salvando as mudan&ccedil;as no artigo")
    const articleURL = `${process.env.REACT_APP_BACKEND_URL}articles/${id}`;
    setSaving(true)
    const accessToken = await getAccessTokenSilently();
    let header: RawAxiosRequestHeaders = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
    const putBody: IArticle = values;
    axios.put(articleURL, putBody, {
      headers: header
    }).then(res => {
      console.log(res)
      queryClient.removeQueries(['editarticle', id], { exact: true })
      alert('save successful')
    }).catch((err: AxiosError | Error) => {
      if (axios.isAxiosError(err)) {
        console.error(err.response?.data);
      }
      alert('save failed')
    }).finally(() => {
      setSaving(false)
    })
  }

  const deleteArticle = async () => {
    const articleURL = `${process.env.REACT_APP_BACKEND_URL}articles/${id}`;
    setSpinnerDialogText("Apagando o artigo")
    setSaving(true)
    const accessToken = await getAccessTokenSilently();
    let header: RawAxiosRequestHeaders = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
    axios.delete(articleURL, {
      headers: header
    }).then(res => {
      console.log(res)
      queryClient.removeQueries(['editarticle', id], { exact: true })
      alert('O artigo foi apagado')
      navigate("/articles")
    }).catch((err: AxiosError | Error) => {
      if (axios.isAxiosError(err)) {
        console.error(err.response?.data);
      }
      alert('erro apagando o artigo')
    }).finally(() => {
      setSaving(false)
    })
  }

  const [articleQuery, imagesQuery] = useQueries([
    {
      queryKey: ['editarticle', id],
      queryFn: loadArticle,
      enabled: !auth0Loading,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false
    },
    {
      queryKey: ['images'],
      queryFn: getImages,
      enabled: !auth0Loading
    }
  ]
  )
  if (user && !user['peloestudo/roles'].includes('Owner')) {
    return (<Navigate to="/" />)
  }

  if (articleQuery.status === 'loading' || imagesQuery.status === 'loading') {
    return <div><ClipLoader /></div>
  }

  if (articleQuery.status === 'error' || imagesQuery.status === 'error') {
    const errors: any[] = [];
    if (articleQuery.error) {
      if (axios.isAxiosError(articleQuery.error)) {
        if (articleQuery.error.response?.status === 404) {
          return (<div>
            Article not found.
            <br />
            {returnHome}
          </div>)
        }
      }
      errors.push(articleQuery.error)
    }
    if (imagesQuery.status === 'error') {
      errors.push(imagesQuery.error)
    }
    console.error(errors)
    return <div>Error loading Article</div>
  }

  if (articleQuery.data !== undefined && imagesQuery.data !== undefined) {
    return (
      <section style={pageStyle}>
        <h2>Edita o artigo</h2>
        <div style={gridStyle}>
          {articleImages.length > 0 &&
            (<><label>Imag&eacute;m do Artigo</label>
              <div>
                {values.articleImage &&
                  <div>
                    <img src={values.articleImage} alt={values.title} style={headerImageStyle} />
                  </div>
                }
                <select value={values.articleImage} name="articleImage" onChange={handleChange}>
                  {!values.articleImage &&
                    <option></option>
                  }
                  {articleImages.map(x => {
                    return <option value={x.value}>{x.display}</option>
                  })}
                </select>
              </div>
            </>)
          }


          <label>T&iacute;tulo do Artigo:
          </label>
          <input
            type="text"
            name="title"
            onChange={debouncedHandler}
            onBlur={handleBlur}
            defaultValue={values.title}
          />
          <label>Colunista:
          </label>
          <input
            type="text"
            name="author"
            onChange={debouncedHandler}
            defaultValue={values.author}
            onBlur={handleBlur}
          />
          {authorPhotos.length > 0 &&
            <><label>Foto do Colunista</label>
              <div>
                {values.authorPhoto &&
                  <div style={authorPhotoDivStyle}>
                    <img src={values.authorPhoto} alt={`${values.author}`} style={authorPhotoStyle} />
                  </div>
                }
                <select value={values.authorPhoto} name="authorPhoto" onChange={handleChange}>
                  {!values.authorPhoto &&
                    <option></option>
                  }
                  {authorPhotos.map(x => {
                    return <option value={x.value}>{x.display}</option>
                  })}
                </select>
              </div></>
          }
          <label>Approvar Artigo:</label>
          <div><input type="checkbox" checked={!!values.approved} onChange={handleApproveChange} name="approved" /></div>
          <label>Cabe&#231;alho:</label>
          <textarea name="header" onBlur={handleBlur} onChange={debouncedHandler} defaultValue={values.header} />
        </div>

        <Editor
          apiKey='c27sqmj1ce4fnrsz5n0bcciljvxysmhsu5mjm3jzxdr24amf'
          onInit={(evt, editor) => editorRef.current = editor}
          onEditorChange={(newValue, editor) => handleEditorChange(newValue)}
          value={values.content}
          init={{
            height: 500,
            plugins: 'preview code',
            toolbar: [
              { name: 'history', items: ['undo', 'redo'] },
              { name: 'styles', items: ['styles'] },
              { name: 'formatting', items: ['bold', 'italic', 'blockquote'] },
              { name: 'alignment', items: ['alignleft', 'aligncenter', 'alignright', 'alignjustify', 'alignnone'] },
              { name: 'indentation', items: ['outdent', 'indent'] },
              { name: 'preview', items: ['preview', 'code'] }
            ]
          }}
        />
        <div style={buttonDiv}>
          <button onClick={save} disabled={!isFormValid() || saving}>Submit</button>
          <button onClick={() => {
            if (window.confirm("Tem certeza que quer apagar? Não vai poder recuperar.")) { deleteArticle() }
          }} disabled={saving}>Apagar</button>
        </div>
        {
          saving &&
          <SpinnerModal
            spinnerText={spinnerDialogText}
          />
        }
      </section >
    )
  }
  return <></>
}

export default EditArticle;