import { useAuth0 } from "@auth0/auth0-react";
import { Navigate, NavLink } from "react-router-dom";
import AboutUsUsersFeed from './AboutUsUsersFeed'

function UsersAdminPage() {
  const { user } = useAuth0();
  if (user && !user['peloestudo/roles'].includes('Owner')) {
    return (<Navigate to="/" />)
  }
  return (
    <>
      <h2>Selecione Usu&aacute;rio para administrar</h2>
      <AboutUsUsersFeed />
      <NavLink to="New">
        Acrescentar
      </NavLink>
    </>
  )
}

export default UsersAdminPage;