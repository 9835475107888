import { useQuery } from 'react-query';
import axios from 'axios';
import YoutubeGrid from './YoutubeGrid';
import {IYoutubeItem} from './IYoutubeItem';
import { ClipLoader } from 'react-spinners';

const key = process.env.REACT_APP_YOUTUBE_KEY;
const channelID = process.env.REACT_APP_YOUTUBE_CHANNEL_ID;

const searchUrl = `https://www.googleapis.com/youtube/v3/search?key=${key}&channelId=${channelID}&part=snippet,id&order=date&maxResults=20`;
const YoutubeFeed = () => {
  const loadYoutubeVideos = async () => {
    const data = await (await axios.get<any>(searchUrl)).data
    console.log(data.items)
    let items : IYoutubeItem[] = data.items.map(
      (item:
        {id :{
          kind: String,
          videoId: String,
        },
        snippet: {
          title:String,
          thumbnails: {
            default: {
              url: String,
              width: Number,
              height: Number
            }
          }
        }
      }) => {
      console.log(item)
      return {id: item.id.videoId, title: item.snippet.title,thumbnails: item.snippet.thumbnails }
    });

    console.log(items)
    
    return items
  }

  const {status, data, error} = useQuery('youtubeVideos', loadYoutubeVideos)

  if (status === 'loading') {
    return <ClipLoader />
  }

  if (status === 'error') {
    console.log(error)
    return <div>Error loading data</div>
  }

  if (data !== undefined) {
    return (
      <YoutubeGrid items={data}></YoutubeGrid>
    )
  }
  return <></>
  
}

export default YoutubeFeed