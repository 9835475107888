const footerParagraphStyle: React.CSSProperties = {
  margin: "auto"
}
export const Footer = () => {
  return (
    <div className="App-footer">
      <div style={footerParagraphStyle}>
        Direitos do autor © 2023 peloestudoepelafe.org Todos os direitos reservados.<br/>
        <a href="mailto:peloestudoepelafe@gmail.com" style={{color:"white"}}>Entre em contato connosco</a>
      </div>
    </div>
  )

}