import { Seo } from "widgets/Seo";
import logo from 'Logo.png';
import SocialMediaPartnersFeed from './SocialMediaPartnersFeed'
import { NavLink } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

function SocialMediaPartners() {
  const { user } = useAuth0();
  return (
    <>
      <Seo
        title={`${process.env.REACT_APP_SITE_TITLE} | Artigos`}
        description='Lista de artigos'
        type="website"
        name="Pelo Estudo e Pela F&eacute;"
        image={logo}
      />
      <h2>Social Media Partners</h2>
      <SocialMediaPartnersFeed />
      {user?.['peloestudo/roles'].includes('Owner') &&
        <NavLink to="New">
          Acrescentar
        </NavLink>
      }
    </>
  );
}

export default SocialMediaPartners;