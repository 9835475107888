import { IAboutUsUser } from "./IAboutUsUser";
import { NavLink } from 'react-router-dom';

interface AboutUsUserProps {
  items: IAboutUsUser[]
}

const AboutUsUserList = ({ items }: AboutUsUserProps) => {
  if (items.length === 0) {
    return <div></div>
  }
  return (
    <ul>
      {items.map((user: IAboutUsUser, index: number) => {
        return (
          <li key={index}>
            <p>
              <NavLink to={`${user.id}`}>
                {user.name}
              </NavLink>
            </p>
          </li>
        )
      })}
    </ul>
  )
}

export default AboutUsUserList