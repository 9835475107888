import { Editor } from '@tinymce/tinymce-react';
import { Editor as TinyMCEEditor } from 'tinymce';
import React, { useRef, useState } from 'react';
import { debounce } from 'lodash';
import axios, { AxiosError, RawAxiosRequestHeaders } from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import SpinnerModal from 'Components/SpinnerModal';
import { Navigate } from 'react-router-dom';
import { IImage } from 'Admin/Images/IImage';
import { ClipLoader } from 'react-spinners';
import { useQuery } from 'react-query';

const pageStyle: React.CSSProperties = {
  textAlign: 'left'
}

const gridStyle: React.CSSProperties = {
  display: 'grid',
  gridTemplateColumns: '200px auto',
  maxWidth: '1000px',
  columnGap: '1em',
  rowGap: '.5em',
  fontSize: '1.5em'
}

const bookReviewImageStyle: React.CSSProperties = {
  maxWidth: '100px',
  width: '100%'
}

function NewBookReview() {
  const getImages = async () => {
    let header: RawAxiosRequestHeaders = {
      'Content-Type': 'application/json'
    }
    const accessToken = await getAccessTokenSilently();
    header.Authorization = `Bearer ${accessToken}`;
    const imageUrl = `${process.env.REACT_APP_BACKEND_URL}images`;
    const data = await (await axios.get<any>(imageUrl, {
      headers: header
    })).data
    const mappedImages: { display: string, value: string, imageType?: string }[] =
      data.images.filter((x: IImage) => !x.hidden).map((x: IImage) => {
        return {
          display: x.name,
          value: x.imageUrl,
          imageType: x.imageType
        }
      })

    const bookReviewPics = mappedImages.filter(x => x.imageType === 'bookReview')
    setBookReviewImages(bookReviewPics);
    return data;
  }
  const { user, getAccessTokenSilently, isLoading: auth0Loading } = useAuth0();
  
  const bookReviewURL = `${process.env.REACT_APP_BACKEND_URL}bookReviews`;
  const initialValues:{
    bookReviewTitle: string,
    authorName: string,
    content: string,
    header: string,
    bookReviewImage?: string
  } = {
    bookReviewTitle: '',
    authorName: user?.name || '',
    content: '',
    header: ''
  }
  const [values, setValues] = useState(initialValues);
  const [saving, setSaving] = useState(false);
  const [bookReviewImages, setBookReviewImages] = useState<{ display: string, value: string, imageType?: string }[]>([]);
  const editorRef = useRef<TinyMCEEditor | null>(null)
  const { status } = useQuery('bookReviewImages', getImages, {
    enabled: (!auth0Loading && user?.['peloestudo/roles'].includes('Owner'))
  })
  if (user && !user['peloestudo/roles'].includes('Admin')) {
    return (<Navigate to="/BookReviews" />)
  }
  const handleChange = (e: (React.ChangeEvent<HTMLInputElement>| React.ChangeEvent<HTMLTextAreaElement> | React.ChangeEvent<HTMLSelectElement>)) => {
    console.log('handleChange', e)
    setValues({
      ...values,
      [e.target.name]: e.target.value
    })
  }
  const isFormValid = () => {
    console.log('in is form valid')
    return (values.bookReviewTitle?.length > 0 && values.authorName?.length > 0 && values.content?.length > 0 && values.header.length > 0)
  }
  const handleBlur = (e: (React.FocusEvent<HTMLInputElement> | React.FocusEvent<HTMLTextAreaElement>)) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value
    })
  }

  const debouncedHandler = debounce(handleChange, 500);
  const save = async () => {
    setSaving(true)
    const accessToken = await getAccessTokenSilently();
    let header: RawAxiosRequestHeaders = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
    const postBody : {
      author: string,
      content: string,
      title: string,
      header: string,
      authorPhoto?: string,
      bookReviewImage?: string
    } = {
      author: values.authorName,
      content: values.content,
      title: values.bookReviewTitle,
      header: values.header,
      bookReviewImage: values.bookReviewImage
    }
    if (user?.picture !== undefined) {
      postBody.authorPhoto = user.picture
    }
    axios.post(bookReviewURL, postBody , {
      headers: header
    }).then(res => {
      console.log(res)
      alert('save successful')
    }).catch((err: AxiosError | Error) => {
      if (axios.isAxiosError(err)) {
        console.error(err.response?.data);
      }
      alert('save failed')
    }).finally(() => {
      setSaving(false)
    })
  }
  const handleEditorChange = (newValue: string) => {
    setValues({
      ...values,
      content: newValue
    })
  }
  return (
    <section style={pageStyle}>
      <h2>Envie Sua Resenha Aqui</h2>
      <div style={gridStyle}>
        <label>T&iacute;tulo da Resenha:
        </label>
        <input
          type="text"
          name="bookReviewTitle"
          onChange={debouncedHandler}
          onBlur={handleBlur}
          defaultValue={values.bookReviewTitle}
        />
        {status === 'loading' &&
          (
            <div><ClipLoader /></div>
          )
        }
        {
          status === 'error' &&
          (
            <div>Error loading images</div>
          )
        }
        {
          status === 'success' && bookReviewImages.length > 0 &&
          (<><label>Imag&eacute;m da Not&iacute;cia</label>
            <div>
              {values.bookReviewImage &&
                <div>
                  <img src={values.bookReviewImage} alt={values.bookReviewTitle} style={bookReviewImageStyle} />
                </div>
              }
              <select value={values.bookReviewImage} name="bookReviewImage" onChange={handleChange}>
                {!values.bookReviewImage &&
                  <option></option>
                }
                {bookReviewImages.map(x => {
                  return <option value={x.value}>{x.display}</option>
                })}
              </select>
            </div>
          </>)
        }
        <label>Colunista:
        </label>
        <input
          type="text"
          name="authorName"
          onChange={debouncedHandler}
          defaultValue={values.authorName}
          onBlur={handleBlur}
          disabled={user?.name?.length !== undefined && user.name.length > 0}
        />
        <label>Cabe&#231;alho:</label>
        <textarea name="header" onBlur={handleBlur} onChange={debouncedHandler} defaultValue={values.header}/>
      </div>

      <Editor
        apiKey='c27sqmj1ce4fnrsz5n0bcciljvxysmhsu5mjm3jzxdr24amf'
        onInit={(evt, editor) => editorRef.current = editor}
        onEditorChange={(newValue, editor) => handleEditorChange(newValue)}
        value={values.content}
        init={{
          height: 500,
          plugins: 'preview code',
          toolbar: [
            { name: 'history', items: ['undo', 'redo'] },
            { name: 'styles', items: ['styles'] },
            { name: 'formatting', items: ['bold', 'italic', 'blockquote'] },
            { name: 'alignment', items: ['alignleft', 'aligncenter', 'alignright', 'alignjustify', 'alignnone'] },
            { name: 'indentation', items: ['outdent', 'indent'] },
            { name: 'preview', items: ['preview', 'code'] }
          ]
        }}
      />
      <button onClick={save} disabled={!isFormValid() || saving}>Submit</button>
      {
        saving &&
        <SpinnerModal
          spinnerText="Obrigado pelo envio da resenha"
        />
      }
    </section>
  );
}

export default NewBookReview;