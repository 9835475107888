export enum networks {
  Facebook = "Facebook",
  Instagram = "Instagram",
  Twitter = "Twitter",
  Youtube = "Youtube"
}

export interface ISocialMediaPartner {
  id?: string,
  url: string,
  description?: string,
  mediaType: networks,
  name: string
}