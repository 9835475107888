import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faYoutube,
  // faFacebook,
  // faTwitter,
  faInstagram
} from "@fortawesome/free-brands-svg-icons";

const SocialMediaWidget = () => {
  return (
    <div className="social-container">
      <h4>Siga nos</h4>
      <a href="https://youtube.com/@peloestudoepelafe"
      className="youtube social">
        <FontAwesomeIcon icon={faYoutube} size="2x" />
      </a>
      <a href="https://instagram.com/peloestudoepelafe"
      className="instagram social">
      <FontAwesomeIcon icon={faInstagram} size="2x" />
      </a>
    </div>
  )
}

export default SocialMediaWidget