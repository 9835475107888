import CommentsFeed from './CommentsFeed';
import { useState } from 'react';

interface CommentsSectionProps {
  articleId: string
}

function CommentsSection({ articleId }: CommentsSectionProps) {
  const [showComments, setShowComments] = useState(false);

  return (
    <>
      {
      !showComments &&
        < button onClick={() => { setShowComments(true) }}>Mostra Coment&aacute;rios</button >
      }
      {
        showComments &&
        <CommentsFeed articleId={articleId} />
      }
    </>
  )

}

export default CommentsSection;