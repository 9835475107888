import { IAdvertisement } from "Advertisements/IAdvertisement";
import { NavLink } from 'react-router-dom';
interface AdvertisementProps {
  items: IAdvertisement[]
}

const AdvertisementList = ({ items }: AdvertisementProps) => {
  if (items.length === 0) {
    return <div></div>
  }
  return (
    <ul>
      {items.map((advertisement: IAdvertisement, index: number) => {
        return (
          <li key={index}>
            <p>
              <NavLink to={`${advertisement.id}`}>
                {advertisement.companyName}
              </NavLink>
            </p>
          </li>
        )
      })}
    </ul>
  )
}

export default AdvertisementList