import {useAuth0} from '@auth0/auth0-react';
import { Navigate, NavLink } from "react-router-dom";
import AdvertisementFeed from "Advertisements/AdvertisementFeed";

const AdvertisementAdminPage = () => {
  const { user } = useAuth0();
  if (user && !user['peloestudo/roles'].includes('Owner')) {
    return (<Navigate to="/" />)
  }

  if (user) {
    return (
      <>
        <AdvertisementFeed />
        <NavLink to="New">
          Acrescentar
        </NavLink>
      </>
    )
  }
  return <></>
}

export default AdvertisementAdminPage