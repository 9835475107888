import { useQuery } from 'react-query';
import axios, { RawAxiosRequestHeaders } from 'axios';
import { IAboutUsUser } from './IAboutUsUser';
import AboutUsUserList from './AboutUsUserList';
import { useAuth0 } from '@auth0/auth0-react';
import ClipLoader from 'react-spinners/ClipLoader';
import { FunctionComponent } from 'react';
import OrganizedAboutUsUserLIst from './OrganizedAboutUsUserList';

const usersURL = `${process.env.REACT_APP_BACKEND_URL}aboutUsUsers`;
const AboutUsUsersFeed: FunctionComponent<{ organized?: boolean }> = (props) => {
  const { isAuthenticated, getAccessTokenSilently, isLoading: auth0Loading } = useAuth0();
  const loadUsers = async () => {
    let header: RawAxiosRequestHeaders = {
      'Content-Type': 'application/json'
    }
    if (isAuthenticated) {
      const accessToken = await getAccessTokenSilently();
      header.Authorization = `Bearer ${accessToken}`;
    }
    const data = await (await axios.get<any>(usersURL, {
      headers: header
    })).data
    let items: IAboutUsUser[] = data.data.map(
      (item:
        {
          id: string,
          name: string,
          description: string,
          type: string,
          photoUrl?: string
        }) => {
        return {
          id: item.id,
          name: item.name,
          description: item.description,
          type: item.type,
          photoUrl: item.photoUrl
        }
      });

    return items
  }

  const { status, data, error } = useQuery('users', loadUsers, {
    enabled: !auth0Loading
  })

  if (status === 'loading') {
    return (
      <div>
        <ClipLoader />
      </div>
    )
  }

  if (status === 'error') {
    console.log(error)
    return <div>Error loading Articles</div>
  }

  if (data !== undefined) {
    if (props.organized) {
      return (
        <OrganizedAboutUsUserLIst items={data}></OrganizedAboutUsUserLIst>
      )
    }
    return (
      <AboutUsUserList items={data}></AboutUsUserList>
    )
  }

  return <></>

}

export default AboutUsUsersFeed