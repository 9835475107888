import React, { useState } from "react"
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import { useAuth0 } from '@auth0/auth0-react';
import SpinnerModal from 'Components/SpinnerModal';
import { Navigate } from 'react-router-dom';
import axios, { AxiosError, RawAxiosRequestHeaders } from 'axios';

const viewerStyle: React.CSSProperties = {
  width: "500px",
  height: "500px"
}

function UploadPicturePage() {
  const [selectedFile, setSelectedFile] = useState<(File | undefined)>();
  const [isSelected, setIsSelected] = useState(false);
  const [imageType, setImageType] = useState("");
  const [saving, setSaving] = useState(false);
  const { user, getAccessTokenSilently } = useAuth0();

  const photoURL = `${process.env.REACT_APP_BACKEND_URL}images`;

  if (user && !user['peloestudo/roles'].includes('Owner')) {
    return (<Navigate to="/" />)
  }

  const changeHandler = (event: (React.ChangeEvent<HTMLInputElement>)) => {
    if (event.target.files?.length) {
      setSelectedFile(event.target.files[0])
      setIsSelected(true)
    } else {
      setSelectedFile(undefined)
      setIsSelected(false)
    }
  }

  const itemSelected = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setImageType(event.target.value)
  }

  const save = async () => {
    setSaving(true)
    if (selectedFile === undefined || !imageType) {
      return
    }
    const accessToken = await getAccessTokenSilently();
    let header: RawAxiosRequestHeaders = {
      'Authorization': `Bearer ${accessToken}`
    }
    const formData = new FormData();
    formData.append('file', selectedFile);
    formData.append('imageType', imageType)
    axios.post(photoURL, formData, {
      headers: header
    }).then(res => {
      setSelectedFile(undefined);
      setImageType("");
      console.log(res)
      alert('save successful')
    }).catch((err: AxiosError | Error) => {
      if (axios.isAxiosError(err)) {
        console.error(err.response?.data);
      }
      alert('save failed')
    }).finally(() => {
      setSaving(false)
    })
  }

  return (
    <div>
      <input type="file" onChange={changeHandler} accept="image/*" />
      {selectedFile !== undefined ? (
        <div>
          <DocViewer
            style={viewerStyle}
            documents={
              [{
                uri: window.URL.createObjectURL(selectedFile),
                fileName: selectedFile.name
              }]
            }
            pluginRenderers={DocViewerRenderers}
          />
        </div>) :

        (<p>Select a file to show details</p>)
      }
      <select onChange={itemSelected} value={imageType}>
        <option></option>
        <option value="userPhoto">Usu&aacute;rio</option>
        <option value="articlePhoto">Art&iacute;go</option>
        <option value="newsPhoto">Not&iacute;cia</option>
        <option value="adPhoto">Propaganda</option>
        <option value="bookReview">Resenha</option>
      </select> <br />
      <button disabled={!isSelected || !imageType} onClick={save}>Submit</button>

      {
        saving &&
        <SpinnerModal
          spinnerText="Espera enquanto salvamos a imagem"
        />
      }
    </div>



  )
}

export default UploadPicturePage