import { useAuth0 } from '@auth0/auth0-react';
import { Navigate, NavLink } from 'react-router-dom';

function PictureAdminPage() {
  const { user } = useAuth0();

  if (user && !user['peloestudo/roles'].includes('Owner')) {
    return (<Navigate to="/" />)
  }

  return (
    <>
        <h2>P&aacute;gina de Administrar Imagens</h2>
        <ul style={{listStyle: "none"}}>
          <li><NavLink to="New">Cria Imagem</NavLink></li>
          <li><NavLink to="Hide">Esconder Imagens</NavLink></li>
        </ul>
      </>
  )
}

export default PictureAdminPage