import { useQuery } from 'react-query';
import axios, { RawAxiosRequestHeaders } from 'axios';
import { IComment } from './IComment';
import CommentsList from './CommentsList';
import { useAuth0 } from '@auth0/auth0-react';
import ClipLoader from 'react-spinners/ClipLoader';
import React, { FunctionComponent } from 'react';

interface CommentsFeedProps {
  articleId: string
}

const CommentsFeed: FunctionComponent<CommentsFeedProps> = (props) => {
  const commentsURL = `${process.env.REACT_APP_BACKEND_URL}comments/article/${props.articleId}`;
  const { isAuthenticated, getAccessTokenSilently, isLoading: auth0Loading } = useAuth0();
  const loadArticleComments = async () => {
    let header: RawAxiosRequestHeaders = {
      'Content-Type': 'application/json'
    }
    if (isAuthenticated) {
      const accessToken = await getAccessTokenSilently();
      header.Authorization = `Bearer ${accessToken}`;
    }
    const data = await (await axios.get<any>(commentsURL, {
      headers: header
    })).data
    let items: IComment[] = data.comments.map(
      (item: {
        id: string,
        content: string,
        user: string,
        dateSubmitted: string,
        approved?: Boolean,
        articleId: string,
        parent?: string,
        deleted?: Boolean,
        deletedContent?: string,
        children?: IComment[]
      }) => {
        return {
          id: item.id,
          content: item.content,
          user: item.user,
          dateSubmitted: new Date(item.dateSubmitted),
          approved: item.approved,
          articleId: item.articleId,
          deleted: item.deleted,
          deletedContent: item.deletedContent,
          children: item.children
        } as IComment
      });

    return items
  }

  const { status, data, error } = useQuery(['loadArticleComments', props.articleId], loadArticleComments, {
    enabled: !auth0Loading
  })

  if (status === 'loading') {
    return (
      <div>
        <ClipLoader />
      </div>
    )
  }

  if (status === 'error') {
    console.log(error)
    return <div>Error loading Comments</div>
  }

  if (data !== undefined) {
    return (
      <>
        <h2>Coment&aacute;rios</h2>
        <CommentsList items={data} articleId={props.articleId}></CommentsList>
      </>
    )
  }

  return <></>

}

export default CommentsFeed