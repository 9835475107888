import { useState } from 'react';
import { debounce } from 'lodash';
import axios, { AxiosError, RawAxiosRequestHeaders } from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import SpinnerModal from 'Components/SpinnerModal';
import { Navigate } from 'react-router-dom';
import { ISocialMediaPartner, networks } from './ISocialMediaPartner';

const pageStyle: React.CSSProperties = {
  textAlign: 'left'
}

const gridStyle: React.CSSProperties = {
  display: 'grid',
  gridTemplateColumns: '200px auto',
  maxWidth: '1000px',
  columnGap: '1em',
  rowGap: '.5em',
  fontSize: '1.5em'
}

function NewSocialMediaPartner() {
  const { user, getAccessTokenSilently } = useAuth0();
  
  const partnerURL = `${process.env.REACT_APP_BACKEND_URL}socialMediaPartners`;
  const initialValues = {
    url: '',
    description: '',
    name: ''
  } as ISocialMediaPartner
  const [values, setValues] = useState(initialValues);
  const [saving, setSaving] = useState(false);
  if (user && (!user['peloestudo/roles'].includes('Owner'))) {
    return (<Navigate to="/SocialMediaPartners" />)
  }
  const handleChange = (e: (React.ChangeEvent<HTMLInputElement>| React.ChangeEvent<HTMLTextAreaElement> | React.ChangeEvent<HTMLSelectElement>)) => {
    console.log('handleChange', e)
    setValues({
      ...values,
      [e.target.name]: e.target.value
    })
  }

  const isFormValid = () => {
    console.log('in is form valid')
    return (values.name.length > 0 && values.url.length > 0 && values.mediaType)
  }
  const handleBlur = (e: (React.FocusEvent<HTMLInputElement> | React.FocusEvent<HTMLTextAreaElement>)) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value
    })
  }
  const debouncedHandler = debounce(handleChange, 500);
  const save = async () => {
    setSaving(true)
    const accessToken = await getAccessTokenSilently();
    let header: RawAxiosRequestHeaders = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
    const postBody : {
      linkUrl: string,
      description?: string,
      name: string,
      network: string
    } = {
      linkUrl: values.url,
      description: values.description,
      name: values.name,
      network: values.mediaType.toString()
    }
    axios.post(partnerURL, postBody , {
      headers: header
    }).then(res => {
      console.log(res)
      alert('save successful')
    }).catch((err: AxiosError | Error) => {
      if (axios.isAxiosError(err)) {
        console.error(err.response?.data);
      }
      alert('save failed')
    }).finally(() => {
      setSaving(false)
    })
  }
  return (
    <section style={pageStyle}>
      <h2>Cria Novo Parceiro</h2>
      <div style={gridStyle}>
        <label>Nome do Parceiro:
        </label>
        <input
          type="text"
          name="name"
          onChange={debouncedHandler}
          onBlur={handleBlur}
          defaultValue={values.name}
        />
        <label>Endere&#231;o:
        </label>
        <input
          type="text"
          name="url"
          onChange={debouncedHandler}
          defaultValue={values.url}
          onBlur={handleBlur}
        />
        <label>Descri&#231;&atilde;o:</label>
        <textarea name="description" onBlur={handleBlur} onChange={debouncedHandler} defaultValue={values.description}/>
        <label>Rede</label>
        <select name='mediaType' onChange={handleChange}>
          <option></option>
          <option value={networks.Facebook}>{networks.Facebook}</option>
          <option value={networks.Instagram}>{networks.Instagram}</option>
          <option value={networks.Twitter}>{networks.Twitter}</option>
          <option value={networks.Youtube}>{networks.Youtube}</option>
        </select>
      </div>
      <button onClick={save} disabled={!isFormValid() || saving}>Submit</button>
      {
        saving &&
        <SpinnerModal
          spinnerText="Salvando Rede"
        />
      }
    </section>
  );
}

export default NewSocialMediaPartner;