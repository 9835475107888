import { IComment } from "./IComment";
import { useAuth0 } from "@auth0/auth0-react";
import CommentDisplay from './CommentDisplay';
import { useState } from "react";
import NewComment from "./NewComment";
import LoginButton from "widgets/LoginButton";

interface CommentsListProps {
  items: IComment[],
  articleId: string
}

const CommentsList = ({ items, articleId }: CommentsListProps) => {
  const [makeComment, setMakeComment] = useState(false);
  const renderComment = () => {
    if (!makeComment) {
      return (<button onClick={() => setMakeComment(true)}>Novo coment&aacute;rio</button>)
    } return (
      <NewComment articleId={articleId} />
    )
  }
  const { user } = useAuth0();
  if (items.length === 0) {
    return (
      <>
        <div>Nenhum coment&aacute;rio ainda.</div>
        {user &&
          renderComment()
        }
        {!user &&
          <p>Para fazer coment&aacute;rio, <LoginButton /></p>
        }
      </>
    )
  }
  return (
    <>
      {items.map((comment: IComment, index: number) => {
        return (
          <CommentDisplay comment={comment} />
        )
      })}
      {user &&
        renderComment()
      }
      {!user &&
        <p>Para fazer coment&aacute;rio, <LoginButton /></p>
      }
    </>
  )
}

export default CommentsList;