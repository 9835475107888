import React, { useState } from "react"
import { useAuth0 } from '@auth0/auth0-react';
import { Navigate, NavLink } from 'react-router-dom';
import { useQuery } from 'react-query';
import { ClipLoader } from 'react-spinners';
import { IImage } from "./IImage";
import axios, { AxiosError, RawAxiosRequestHeaders } from 'axios';
import SpinnerModal from 'Components/SpinnerModal';

const headerImageStyle: React.CSSProperties = {
  maxWidth: '100px',
  width: '100%'
}

const returnHome = (
  <NavLink to="/Articles">
    Articles
  </NavLink>
)

function HidePicturePage() {
  const emptyPhotoArray: IImage[] = []
  const { user, getAccessTokenSilently, isLoading: auth0Loading } = useAuth0();
  const [saving, setSaving] = useState(false);
  const [userPhotos, setUserPhotos] = useState(emptyPhotoArray)
  const [articlePhotos, setArticlePhotos] = useState(emptyPhotoArray)
  const [newsPhotos, setNewsPhotos] = useState(emptyPhotoArray)
  const [adPhotos, setAdPhotos] = useState(emptyPhotoArray)
  const [changedHidden, setChangedHidden] = useState(false)
  const [spinnerDialogText, setSpinnerDialogText] = useState("");

  const loadImages = async () => {
    let header: RawAxiosRequestHeaders = {
      'Content-Type': 'application/json'
    }
    const accessToken = await getAccessTokenSilently();
    header.Authorization = `Bearer ${accessToken}`;
    const imageUrl = `${process.env.REACT_APP_BACKEND_URL}images`;
    const data = await (await axios.get<any>(imageUrl, {
      headers: header
    })).data
    const mappedImages: IImage[] = data.images.map((x: IImage) => {
      return {
        id: x.id,
        imageUrl: x.imageUrl,
        imageType: x.imageType,
        name: x.name,
        hidden: !!x.hidden
      }
    })

    setUserPhotos(mappedImages.filter(x => x.imageType === 'userPhoto'))
    setArticlePhotos(mappedImages.filter(x => x.imageType === 'articlePhoto'))
    setNewsPhotos(mappedImages.filter(x => x.imageType === 'newsPhoto'))
    setAdPhotos(mappedImages.filter(x => x.imageType === 'adPhoto'))
    return mappedImages
  }

  const handleUserCheckboxClicked = (e: React.ChangeEvent<HTMLInputElement>) => {
    const index = userPhotos.findIndex(x => x.id === e.target.value)
    if (index > -1) {
      setUserPhotos(updatePhotoHidden(userPhotos, index, e.target.checked))
      setChangedHidden(true)
    }
  }

  const handleArticleCheckboxClicked = (e: React.ChangeEvent<HTMLInputElement>) => {
    const index = articlePhotos.findIndex(x => x.id === e.target.value)
    if (index > -1) {
      setArticlePhotos(updatePhotoHidden(articlePhotos, index, e.target.checked))
      setChangedHidden(true)
    }
  }
  const handleNewsCheckboxClicked = (e: React.ChangeEvent<HTMLInputElement>) => {
    const index = newsPhotos.findIndex(x => x.id === e.target.value)
    if (index > -1) {
      setNewsPhotos(updatePhotoHidden(newsPhotos, index, e.target.checked))
      setChangedHidden(true)
    }
  }
  const handleAdCheckboxClicked = (e: React.ChangeEvent<HTMLInputElement>) => {
    const index = adPhotos.findIndex(x => x.id === e.target.value)
    if (index > -1) {
      setAdPhotos(updatePhotoHidden(adPhotos, index, e.target.checked))
      setChangedHidden(true)
    }
  }

  const updatePhotoHidden = (photoArray: IImage[], index: number, hidden: boolean) => {
    const updatedPhoto = { ...photoArray[index], hidden: hidden }
    const photos = [...photoArray]
    photos[index] = updatedPhoto
    return photos;
  }

  const { status } = useQuery('adminPhotos', loadImages, {
    enabled: (!auth0Loading && user?.['peloestudo/roles'].includes('Owner')),
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false
  })

  if (user && !user['peloestudo/roles'].includes('Owner')) {
    return (<Navigate to="/" />)
  }

  const save = async () => {
    setSpinnerDialogText("Salvando as mudanças nas photos")
    const imageURL = `${process.env.REACT_APP_BACKEND_URL}images/setHidden`;
    setSaving(true)
    const accessToken = await getAccessTokenSilently();
    let header: RawAxiosRequestHeaders = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
    const imageArray: IImage[] = userPhotos.concat(articlePhotos, newsPhotos, adPhotos);
    const postBody: { images: IImage[] } = { images: imageArray };
    axios.post(imageURL, postBody, {
      headers: header
    }).then(res => {
      console.log(res)
      alert('save successful')
    }).catch((err: AxiosError | Error) => {
      if (axios.isAxiosError(err)) {
        console.error(err.response?.data);
      }
      alert('save failed')
    }).finally(() => {
      setSaving(false)
    })
  }

  return (
    <>
      <h2>Esconder Imagens</h2>
      {status === 'loading' &&
        (
          <div><ClipLoader /></div>
        )
      }
      {
        status === 'error' &&
        (
          <>
            <div>Error loading images</div>
            {returnHome}
          </>
        )
      }
      {
        status === 'success' && (userPhotos.length > 0 || articlePhotos.length > 0 || newsPhotos.length > 0 || adPhotos.length > 0) &&
        (
          <>
            {
              userPhotos.length > 0 && (
                <>
                  <h3>Usu&aacute;rios</h3>
                  <table>
                    {
                      userPhotos.map((photo: IImage, index: number) => {
                        return (
                          <tr>
                            <td>
                              <input type="checkbox" value={photo.id} checked={photo.hidden} onChange={handleUserCheckboxClicked} />
                            </td>
                            <td>
                              <img src={photo.imageUrl} alt={photo.name} style={headerImageStyle} />
                            </td>
                          </tr>
                        )
                      })
                    }
                  </table>
                </>
              )
            }
            {
              articlePhotos.length > 0 && (
                <>
                  <h3>Artigos</h3>
                  <table>
                    {
                      articlePhotos.map((photo: IImage, index: number) => {
                        return (
                          <tr>
                            <td>
                              <input type="checkbox" value={photo.id} checked={photo.hidden} onChange={handleArticleCheckboxClicked} />
                            </td>
                            <td>
                              <img src={photo.imageUrl} alt={photo.name} style={headerImageStyle} />
                            </td>
                          </tr>
                        )
                      })
                    }
                  </table>
                </>
              )
            }
            {
              newsPhotos.length > 0 && (
                <>
                  <h3>Not&iacute;cias</h3>
                  <table>
                    {
                      newsPhotos.map((photo: IImage, index: number) => {
                        return (
                          <tr>
                            <td>
                              <input type="checkbox" value={photo.id} checked={photo.hidden} onChange={handleNewsCheckboxClicked} />
                            </td>
                            <td>
                              <img src={photo.imageUrl} alt={photo.name} style={headerImageStyle} />
                            </td>
                          </tr>
                        )
                      })
                    }
                  </table>
                </>
              )
            }
            {
              adPhotos.length > 0 && (
                <>
                  <h3>Propagandas</h3>
                  <table>
                    {
                      adPhotos.map((photo: IImage, index: number) => {
                        return (
                          <tr>
                            <td>
                              <input type="checkbox" value={photo.id} checked={photo.hidden} onChange={handleAdCheckboxClicked} />
                            </td>
                            <td>
                              <img src={photo.imageUrl} alt={photo.name} style={headerImageStyle} />
                            </td>
                          </tr>
                        )
                      })
                    }
                  </table>
                </>
              )
            }
            {
              saving &&
              <SpinnerModal
                spinnerText={spinnerDialogText}
              />
            }
            <button onClick={save} disabled={!changedHidden}>Salvar</button>
          </>
        )

      }
    </>
  )
}

export default HidePicturePage