import { useQuery } from 'react-query';
import axios, { RawAxiosRequestHeaders } from 'axios';
import { ISocialMediaPartner } from './ISocialMediaPartner';
import SocialMediaPartnersList from './SocialMediaPartnersList';
import { useAuth0 } from '@auth0/auth0-react';
import ClipLoader from 'react-spinners/ClipLoader';

const partnerURL = `${process.env.REACT_APP_BACKEND_URL}socialMediaPartners`;
const SocialMediaPartnersFeed = () => {
  const { isAuthenticated, getAccessTokenSilently, isLoading: auth0Loading } = useAuth0();
  const loadPartners = async () => {
    let header: RawAxiosRequestHeaders = {
      'Content-Type': 'application/json'
    }
    if (isAuthenticated) {
      const accessToken = await getAccessTokenSilently();
      header.Authorization = `Bearer ${accessToken}`;
    }
    const data = await (await axios.get<any>(partnerURL, {
      headers: header
    })).data
    let items: ISocialMediaPartner[] = data.data.map(
      (item:
        {
          id: string,
          linkUrl: string,
          description?: string,
          network: string,
          name: string
        }) => {
        return  {
          id: item.id,
          url: item.linkUrl,
          description: item.description,
          mediaType: item.network,
          name: item.name
        } as ISocialMediaPartner
      });

    return items
  }

  const { status, data, error } = useQuery('partners', loadPartners, {
    enabled: !auth0Loading})

  if (status === 'loading') {
    return (
      <div>
        <ClipLoader />
      </div>
    )
  }

  if (status === 'error') {
    console.log(error)
    return <div>Error loading Social Media Partners</div>
  }
  
  if (data !== undefined ) {
    return (
      <SocialMediaPartnersList items={data}></SocialMediaPartnersList>
    )
  }

  return <></>

}

export default SocialMediaPartnersFeed