import { IBook } from "./IBook";
import { NavLink } from 'react-router-dom';

interface BookProps {
  items: IBook[]
}

const BookList = ({items}: BookProps) => {
  if (items.length === 0) {
    return <div></div>
  }
  return (
    <ul>
      {items.map((book: IBook, index:number) => {
        return (
          <li key={index}>
            <p>
            <NavLink to ={`${book.id}`}>
              {book.title}
            </NavLink>
            {` por ${book.author}`}
            </p>
          </li>
        )
      })} 
    </ul>
  )
}

export default BookList