import NewsFeed from './NewsFeed';
import { NavLink } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Seo } from 'widgets/Seo';
import logo from 'Logo.png';


function NewsPage() {
  const { user, isAuthenticated } = useAuth0();
  let canSubmit = false;
  if (isAuthenticated) {
    if (user && user['peloestudo/roles'].includes('Admin')) {
      canSubmit = true
    }
  }
  return (
    <>
      <Seo
        title={`${process.env.REACT_APP_SITE_TITLE} | Notícias`}
        description='Lista de Notícias'
        type="website"
        name="Pelo Estudo e Pela F&eacute;"
        image={logo}
      />
      <h2>Not&iacute;cias</h2>
      <NewsFeed />
      {canSubmit &&
        <NavLink to="submit">
          Adiciona Not&iacute;cia Aqui
        </NavLink>
      }
    </>);

}

export default NewsPage;