import { useAuth0 } from "@auth0/auth0-react";
import { Navigate, NavLink } from "react-router-dom";

function AdminPage() {
  const { user } = useAuth0();
  if (user && !user['peloestudo/roles'].includes('Owner')) {
    return (<Navigate to="/" />)
  }

  if (user) {
    return (
      <>
        <h2>P&aacute;gina de Administrar</h2>
        <ul style={{listStyle: "none"}}>
          <li><NavLink to="Pictures">Imagens</NavLink></li>
          <li><NavLink to="Users">Usu&aacute;rios</NavLink></li>
          <li><NavLink to="Advertisements">Propagandas</NavLink></li>
          <li><NavLink to="AboutUsUsers">Sobre n&oacute;s</NavLink></li>
        </ul>
      </>
    )
  }
  return <></>
}

export default AdminPage;