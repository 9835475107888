import { NavLink } from 'react-router-dom';
import AuthenticationButton from 'widgets/AuthenticationButton';
import logo from 'LogoHeader.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from '@fortawesome/free-solid-svg-icons';
import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import navLinkJson from "./navlinks.json"

const headerStyle: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between'
}
const titleStyle: React.CSSProperties = {
  marginTop: 'auto',
  marginBottom: 'auto',
  fontSize: '1.5em'
}
const iconStyle: React.CSSProperties = {
  height: '100%'
}

const closedNavStyle: React.CSSProperties = {
  display: 'none'
}

const navStyle: React.CSSProperties = {
  display: 'flex',
  padding: '10px'
}

const buttonStyle: React.CSSProperties = {
  height: '100%'
}

const buttonDivStyle: React.CSSProperties = {
  height: '75%',
  marginTop: 'auto',
  marginBottom: 'auto'
}

const openNavStyle: React.CSSProperties = {
  listStyleType: 'none',
  margin: '0',
  backgroundColor: '#3e5c98',
  position: 'absolute',
  right: '5px',
  zIndex: '5000',
  gap: '15px',
  display: 'flex',
  flexDirection: 'column',
  top: '10vh',
  padding: '0px 15px',
  justifyContent: 'normal',
  height: 'fit-content',
  color: 'white',
  left: '40%'
}

function MobileHeader() {
  const { user } = useAuth0();
  const [navbarOpen, setNavbarOpen] = useState(false);
  return (
    <header className="App-header" style={headerStyle}>
      <div className="imageContainer">
        <img src={logo} className="App-logo" alt="logo" style={iconStyle} />
      </div>
      <h1 style={titleStyle}>Pelo Estudo<br /> & Pela F&eacute;</h1>
      <nav style={navStyle}>
        <div style={buttonDivStyle}>
          <button
            style={buttonStyle}
            onClick={() => setNavbarOpen((prev) => !prev)}
          >
            <FontAwesomeIcon icon={faBars} size="2x" />
          </button>
        </div>
        <ul style={navbarOpen ? openNavStyle : closedNavStyle} className="navLinks">
          {
            navLinkJson.map(navLink => {
              if (navLink.requiredRole && !(user && user['peloestudo/roles'].includes(navLink.requiredRole))) {
                return <></>
              }
              return (
                <li key={navLink.key}>
                  <NavLink
                    onClick={() => setNavbarOpen(false)}
                    to={navLink.linkURL}
                  >
                    {navLink.linkLabel}
                  </NavLink>
                </li>

              )
            })
          }
          <li key="hr">
            <hr />
          </li>
          <li key="authButton">
            <AuthenticationButton />
          </li>
        </ul>
      </nav>
    </header >
  )
}

export default MobileHeader;