import AboutUsUsersFeed from './AboutUsUsersFeed';
import { Seo } from 'widgets/Seo';
import logo from 'Logo.png';

const paragraphStyle: React.CSSProperties = {
  maxWidth: '875px',
  textAlign: 'justify',
  marginTop: '50px'
}

const AboutPage = () => {
  return (
    <>
      <Seo
        title={`${process.env.REACT_APP_SITE_TITLE} | Sobre`}
        description='Sobre Nos'
        type="website"
        name="Pelo Estudo e Pela F&eacute;"
        image={logo}
      />
      <h2>Sobre N&oacute;s</h2>
      <div style={paragraphStyle}>
        <p>
          Pelo Estudo e Pela Fé é um projeto de conteúdo multimídia com foco na busca do conhecimento intelectual e espiritual.<br />
          Nossos contribuidores s&atilde;o:
        </p>
        <AboutUsUsersFeed organized />
      </div>
    </>
  )
}

export default AboutPage;