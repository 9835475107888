import { IBookReview } from "./IBookReview";
import { NavLink } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import Moment from 'react-moment';

interface BookReviewProps {
  items: IBookReview[]
}

const containerStyle: React.CSSProperties = {
  fontSize: '1.2em'
}

const centeredStyle: React.CSSProperties = {
  textAlign: 'center'
}

const photoStyle: React.CSSProperties = {
  maxWidth: '750px',
  width: '100%'
}

const BookReviewList = ({ items }: BookReviewProps) => {
  const { user } = useAuth0();
  if (items.length === 0) {
    return <div></div>
  }
  return (
    <>
      {items.map((bookReview: IBookReview, index: number) => {
        return (
          <div key={index} style={containerStyle}>
            {bookReview.bookReviewImage &&
              <div>
                <img src={bookReview.bookReviewImage} alt={bookReview.title} style={photoStyle} />
              </div>
            }
            <h3>
              <NavLink to={`${bookReview.id}`}>
                {bookReview.title}
              </NavLink>
            </h3>
            <p style={centeredStyle}>
              {`${bookReview.author} | `}
              <Moment locale="pt-br" format='ll'>
                {bookReview.dateSubmitted}
              </Moment>

              {(user && user['peloestudo/roles'].includes('Admin')) &&
                <NavLink to={`${bookReview.id}/edit`}>
                  Editar
                </NavLink>
              }
            </p>
            <p style={centeredStyle}>
              {bookReview.header}
            </p>
          </div>
        )
      })}
    </>
  )
}

export default BookReviewList