import { NavLink } from 'react-router-dom';
import AuthenticationButton from 'widgets/AuthenticationButton';
import logo from 'LogoHeader.png';
import { useAuth0 } from '@auth0/auth0-react';
import navLinkJson from "./navlinks.json"

function DesktopHeader() {
  const { user } = useAuth0();
  return (
    <header className="App-header">
      <div className="imageContainer">
        <img src={logo} className="App-logo" alt="logo" />
        <h1>Pelo Estudo & Pela F&eacute;</h1>
      </div>

      <div className="navLinks">
        {
          navLinkJson.map(navLink => {
            if (navLink.requiredRole && !(user && user['peloestudo/roles'].includes(navLink.requiredRole))) {
              return <></>
            }
            return (
              <NavLink to={navLink.linkURL} key={navLink.key}>
                {navLink.linkLabel}
              </NavLink>
            )
          })
        }
      </div>
      <div className="authButton">
        <AuthenticationButton />
      </div>
    </header >
  )
}

export default DesktopHeader;