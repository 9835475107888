import BookFeed from './BookFeed'

function BookPage() {
  return (
    <>
      <h2>Livros</h2>
      <BookFeed />
    </>
  )
}

export default BookPage