import { useQuery } from 'react-query';
import axios, { RawAxiosRequestHeaders } from 'axios';
import { IUser } from './IUser';
import UserList from './UserList';
import { useAuth0 } from '@auth0/auth0-react';
import ClipLoader from 'react-spinners/ClipLoader';

const usersURL = `${process.env.REACT_APP_BACKEND_URL}users`;
const UsersFeed = () => {
  const { isAuthenticated, getAccessTokenSilently, isLoading: auth0Loading } = useAuth0();
  const loadUsers = async () => {
    let header: RawAxiosRequestHeaders = {
      'Content-Type': 'application/json'
    }
    if (isAuthenticated) {
      const accessToken = await getAccessTokenSilently();
      header.Authorization = `Bearer ${accessToken}`;
    }
    const data = await (await axios.get<any>(usersURL, {
      headers: header
    })).data
    let items: IUser[] = data.data.map(
      (item:
        {
          user_id: string,
          name: string,
          user_metadata?: {
            picture?: string
          }
        }) => {
        return  {
          id: item.user_id,
          userName: item.name,
          userImage: item.user_metadata?.picture
        } as IUser
      });

    return items
  }

  const { status, data, error } = useQuery('users', loadUsers, {
    enabled: !auth0Loading})

  if (status === 'loading') {
    return (
      <div>
        <ClipLoader />
      </div>
    )
  }

  if (status === 'error') {
    console.log(error)
    return <div>Error loading Articles</div>
  }
  
  if (data !== undefined ) {
    return (
      <UserList items={data}></UserList>
    )
  }

  return <></>

}

export default UsersFeed