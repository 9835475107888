import { useQuery } from 'react-query';
import axios, { RawAxiosRequestHeaders } from 'axios';
import { IArticle } from './IArticle';
import ArticleList from './ArticleList';
import ArticleCarousel from './ArticleCarousel';
import { useAuth0 } from '@auth0/auth0-react';
import ClipLoader from 'react-spinners/ClipLoader';
import { FunctionComponent } from 'react';

const articleURL = `${process.env.REACT_APP_BACKEND_URL}articles`;
const ArticleFeed:FunctionComponent<{carousel?: boolean}> = (props) => {
  const { isAuthenticated, getAccessTokenSilently, isLoading: auth0Loading } = useAuth0();
  const loadArticles = async () => {
    let header: RawAxiosRequestHeaders = {
      'Content-Type': 'application/json'
    }
    if (isAuthenticated) {
      const accessToken = await getAccessTokenSilently();
      header.Authorization = `Bearer ${accessToken}`;
    }
    const data = await (await axios.get<any>(articleURL, {
      headers: header
    })).data
    let items: IArticle[] = data.data.map(
      (item:
        {
          id: string,
          approved: boolean,
          title: string,
          content: string,
          dateSubmitted: string,
          author: string,
          articleImage?: string,
          header: string
        }) => {
        return  {
          id: item.id,
          title: item.title,
          content: item.content,
          author: item.author,
          dateSubmitted: new Date(item.dateSubmitted),
          approved: item.approved,
          header: item.header,
          articleImage: item.articleImage
        } as IArticle
      });

    return items
  }

  const { status, data, error } = useQuery('articles', loadArticles, {
    enabled: !auth0Loading})

  if (status === 'loading') {
    return (
      <div>
        <ClipLoader />
      </div>
    )
  }

  if (status === 'error') {
    console.log(error)
    return <div>Error loading Articles</div>
  }
  
  if (data !== undefined ) {
    if (props.carousel) {
      return (
        <ArticleCarousel items = {data}></ArticleCarousel>
      )
    }
    return (
      <ArticleList items={data}></ArticleList>
    )
  }

  return <></>

}

export default ArticleFeed