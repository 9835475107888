import DesktopHeader from './DesktopHeader';
import MobileHeader from './MobileHeader';
import { useMediaQuery } from 'react-responsive';

function HeaderComponent() {
  const isDesktop = useMediaQuery({
    query: '(min-width: 1224px)'
  })
  if (isDesktop) {
    return <DesktopHeader />
  }
  return <MobileHeader />
}

export default HeaderComponent;