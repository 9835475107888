import {
  cloneElement, ReactElement, Children, ReactFragment, PropsWithChildren,
  useState, JSXElementConstructor, useEffect, useCallback, useRef
} from "react"

interface CarouselProps {
  dynamicNumber?: boolean,
  gap?: string,
  gridWidth?: string,
  gridBackground?: string,
  margin?: string,
  padding?: string,
  maxWidth?: string
}

const Carousel = (props: PropsWithChildren<CarouselProps>) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [showTransition, setShowTransition] = useState(true)
  const [initialLength, setInitialLength] = useState(0)
  const [renderedChildren, setRenderedChildren] = useState<(string | number | ReactFragment | ReactElement<any, string | JSXElementConstructor<any>>)[] | null | undefined>(null)
  const [articleShift, setArticleShift] = useState(0);
  const gridRef = useRef<HTMLDivElement>(null);
  function isReactElement(child: React.ReactNode): child is React.ReactElement {
    return child instanceof Object && 'props' in child;
  }
  const gridStyle: React.CSSProperties = {
    display: 'flex',
    flexWrap: 'nowrap',
    overflow: 'hidden',
    width: props.gridWidth || '100%',
    maxWidth: props.maxWidth,
    gap: props.gap,
    backgroundColor: props.gridBackground,
    margin: props.margin,
    padding: props.padding
  }

  const carouselInfiniteScroll = useCallback(() => {
    if (gridRef.current && gridRef.current.scrollWidth > gridRef.current.offsetWidth) {
      return setCurrentIndex(currentIndex + 1)
    }
  }, [currentIndex])

  const renderChildren = useCallback(() => {
    let transformCss = '';
    if (props.dynamicNumber) {
      let dynamicShift = 0;
      let initialOffset = 0;
      let minWidth = 0;
      if (gridRef.current) {
        const childArray = Array.from(gridRef.current.childNodes)
        childArray.forEach((child, index) => {
          if (child instanceof HTMLElement) {
            if (index === 0) {
              initialOffset = child.offsetLeft;
            }
            if (index === currentIndex) {
              dynamicShift = (child.offsetLeft - initialOffset)
            }
            minWidth = minWidth === 0? child.offsetWidth: Math.min(minWidth, child.offsetWidth);
          }
        })
        if (minWidth > 0) {
          setArticleShift(Math.ceil(gridRef.current.offsetWidth / minWidth))
        } else {
          setArticleShift(1)
        }
      }
      transformCss = `translate(-${dynamicShift}px)`
    } else {
      transformCss = `translate(-${currentIndex * 100}%)`
      setArticleShift(1)
    }
    const rendered = Children.map(props.children, (child) => {
      if (isReactElement(child)) {
        const customImageStyle: React.CSSProperties = Object.assign({}, child.props.style);
        customImageStyle.transition = showTransition ? '.5s cubic-bezier(0.39, 0.575, 0.565, 1)' : undefined;
        customImageStyle.transform = transformCss;
        return cloneElement(child, { style: customImageStyle })
      } else {
        return child;
      }
    })
    if (Array.isArray(rendered) && rendered?.length > 0) {
      setInitialLength(rendered.length)
      if (gridRef.current && gridRef.current.scrollWidth > gridRef.current.offsetWidth) {
        for (let i: number = 0; i < articleShift; i++) {
          const child = rendered[i];
          if (isReactElement(child)) {
            rendered.push(cloneElement(child, { key: rendered.length }))
          } else {
            rendered?.push(rendered[0])
          }
        }
      }
    }
    setRenderedChildren(rendered)
  }, [currentIndex, props.children, showTransition, props.dynamicNumber, articleShift])

  const handleTransitionEnd = () => {
    if (currentIndex >= initialLength) {
      setShowTransition(false);
      setCurrentIndex(0);
    }
  }

  useEffect(() => {
    const interval = setInterval(() => { carouselInfiniteScroll() }, 6000)
    return () => clearInterval(interval)
  })

  useEffect(() => {
    renderChildren()
    if (currentIndex === 0) {
      setShowTransition(true)
    }
  }, [currentIndex, renderChildren, props.dynamicNumber])

  return (
    <div style={gridStyle} onTransitionEnd={handleTransitionEnd} ref={gridRef}>
      {renderedChildren}
    </div>
  )
}

export default Carousel