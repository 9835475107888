import ArticleFeed from './ArticleFeed';
import { NavLink } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Seo } from 'widgets/Seo';
import logo from 'Logo.png';


function ArticlePage() {
  const { user, isAuthenticated } = useAuth0();
  let canSubmit = false;
  if (isAuthenticated) {
    if (user && (user['peloestudo/roles'].includes('Admin') || user['peloestudo/roles'].includes("Partner"))) {
      canSubmit = true
    }
  }
  return (
    <>
      <Seo
        title={`${process.env.REACT_APP_SITE_TITLE} | Artigos`}
        description='Lista de artigos'
        type="website"
        name="Pelo Estudo e Pela F&eacute;"
        image={logo}
      />
      <h2>Artigos</h2>
      <ArticleFeed />
      {canSubmit &&
        <NavLink to="submit">
          Envie seu artigo aqui
        </NavLink>
      }
    </>);

}

export default ArticlePage;