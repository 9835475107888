import { useQuery } from 'react-query';
import axios, { RawAxiosRequestHeaders } from 'axios';
import { useParams } from 'react-router-dom';
import { INewsItem } from './INewsItem';
import ReactHtmlParser from 'react-html-parser';
import { NavLink } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { Seo } from 'widgets/Seo';
import SocialMediaShareWidget from 'widgets/SocialMediaShare';

const returnHome = (
  <NavLink to="/News">
    Notícias
  </NavLink>
)

const bodyDivStyle: React.CSSProperties = {
  padding: '0 10px'
}
const headerImageStyle: React.CSSProperties = {
  maxWidth: '750px',
  width: '100%'
}

function SingleNewsItem() {
  let { id } = useParams()
  
  const loadNewsItem = async () => {
    let header: RawAxiosRequestHeaders = {
      'Content-Type': 'application/json'
    }
    const newsURL = `${process.env.REACT_APP_BACKEND_URL}news/${id}`;
    const data = await (await axios.get<any>(newsURL, {
      headers: header
    })).data
    const newsItem: INewsItem = {
      title: data.newsItem.title,
      content: data.newsItem.content,
      dateSubmitted: new Date(data.newsItem.dateSubmitted),
      itemImage: data.newsItem.itemImage
    }


    return newsItem
  }

  const { status, data, error } = useQuery('newsItem', loadNewsItem, {
    refetchOnWindowFocus: false,
    retry: (failureCount, error: any) => {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 404) {
          return false;
        }
      }
      return (failureCount < 3)
    }
  })

  if (status === 'loading') {
    return <div><ClipLoader /></div>
  }

  if (status === 'error') {
    if (axios.isAxiosError(error)) {
      console.error('single news item is an axios error', error)
      if (error.response?.status === 404) {
        console.error('single news item is a 404')
        return <div>
          Article not found.
          <br />
          {returnHome}
        </div>
      }
      console.error('single news item axios not 404', error.response?.status)
    }
    console.log(error)
    return <div>Error loading Not&iacute;cia</div>
  }

  if (data !== undefined) {
    return (
      <div>
        <Seo 
          name="Pelo Estudo E Pela Fé"
          title={`${process.env.REACT_APP_SITE_TITLE} | ${data.title}`}
          description="News Article"
          type="Article"
          image={data.itemImage}
        />
        {data.itemImage &&
          <div>
            <img src={data.itemImage} alt={data.title} style={headerImageStyle} />
          </div>
        }
        <div>
          <h2>{data.title}</h2>
          <SocialMediaShareWidget />
        </div>
        <div style={bodyDivStyle}>
          {ReactHtmlParser(data.content)}
        </div>
        {returnHome}
      </div>
    )
  }
  return <></>
}

export default SingleNewsItem;