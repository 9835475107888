import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faYoutube,
  faFacebook,
  faTwitter,
  faInstagram,
  IconDefinition
} from "@fortawesome/free-brands-svg-icons";

import { ISocialMediaPartner, networks } from "./ISocialMediaPartner";
import { NavLink } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
interface SocialMediaProps {
  items: ISocialMediaPartner[]
}

const SocialMediaPartnersList = ({ items }: SocialMediaProps) => {
  const { user } = useAuth0();
  if (items.length === 0) {
    return <div></div>
  }
  return (
    <ul>
      {items.map((partner: ISocialMediaPartner, index: number) => {
        let socialMediaIcon: IconDefinition;
        let mediaClass: string;
        switch(partner.mediaType) {
          case networks.Facebook:
            socialMediaIcon = faFacebook;
            mediaClass = "facebook"
            break;
          case networks.Instagram:
            socialMediaIcon = faInstagram;
            mediaClass = "instagram"
            break;
          case networks.Twitter:
            socialMediaIcon = faTwitter;
            mediaClass = "twitter"
            break;
          case networks.Youtube:
            socialMediaIcon = faYoutube;
            mediaClass = "youtube"
            break;
        }
        return (
          <li key={index}>
            <p>
              <a href={partner.url} className={`social ${mediaClass}`}>
                <FontAwesomeIcon icon={socialMediaIcon} size="2x" />
                {partner.name}
              </a>
              <span>
                {partner.description}
                {user?.['peloestudo/roles'].includes('Owner') &&
                <NavLink to={`${partner.id}`}>
                  Editar
                </NavLink>
              }
              </span>
            </p>
          </li>
        )
      })}
    </ul>
  )
}

export default SocialMediaPartnersList;