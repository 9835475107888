import { useQuery } from 'react-query';
import axios, { RawAxiosRequestHeaders } from 'axios';
import { IAdvertisement } from './IAdvertisement';
import AdvertisementList from 'Admin/Advertisement/AdvertisementList';
import { useAuth0 } from '@auth0/auth0-react';
import ClipLoader from 'react-spinners/ClipLoader';
import { FunctionComponent } from 'react';
import AdvertisementCarousel from './AdvertisementCarousel';

const adsURL = `${process.env.REACT_APP_BACKEND_URL}advertisements`;
const AdvertisementFeed: FunctionComponent<{ carousel?: boolean }> = (props) => {
  const { isAuthenticated, getAccessTokenSilently, isLoading: auth0Loading } = useAuth0();
  const loadAdvertisements = async () => {
    let header: RawAxiosRequestHeaders = {
      'Content-Type': 'application/json'
    }
    if (isAuthenticated) {
      const accessToken = await getAccessTokenSilently();
      header.Authorization = `Bearer ${accessToken}`;
    }
    const data = await (await axios.get<any>(adsURL, {
      headers: header
    })).data
    let items: IAdvertisement[] = data.data.map(
      (item:
        {
          user_id: string,
          name: string,
          user_metadata?: {
            picture?: string
          },
          linkURL: string
        }) => {
        return {
          id: item.user_id,
          companyName: item.name,
          adPhotoURL: item.user_metadata?.picture,
          linkURL: item.linkURL
        } as IAdvertisement
      });

    return items
  }

  const { status, data, error } = useQuery('advertisements', loadAdvertisements, {
    enabled: !auth0Loading
  })

  if (status === 'loading') {
    return (
      <div>
        <ClipLoader />
      </div>
    )
  }

  if (status === 'error') {
    console.log(error)
    return <div>Error loading Advertisements</div>
  }

  if (data !== undefined) {
    if (props.carousel) {
      return (
        <AdvertisementCarousel items={data}></AdvertisementCarousel>
      )
    }

    return (
      <AdvertisementList items={data}></AdvertisementList>
    )
  }

  return <></>

}

export default AdvertisementFeed