import { IBookReview } from "./IBookReview";
import { NavLink } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import Carousel from 'widgets/Carousel';

interface BookReviewProps {
  items: IBookReview[]
}



const BookReviewCarousel = ({ items }: BookReviewProps) => {
  const isDesktop = useMediaQuery({
    query: '(min-width: 1224px)'
  })
  const baseCarouselList = items.slice(0, 9)
  
  let gridBackground = '#3e5c98';
  let gridWidth = 'calc(100% + 10px)';
  let gridMargin = '0 -10px'
  let itemColor = 'black'
  let gridGap = '10%'
  let itemBorder = 'none'

  if (isDesktop) {
    gridBackground = 'white';
    gridWidth = '100%';
    gridMargin = '25px 0px 0px 0px';
    gridGap = '2%';
    itemBorder = '1px solid black';
  }

  const anchorStyle: React.CSSProperties = {
    display: "flex",
    width: "125px",
    minWidth: "125px",
    height: "175px",
    backgroundColor: "white",
    border: itemBorder,
    borderRadius: "5%",
    textDecoration: "none"
  }

  const itemStyle: React.CSSProperties = {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  }

  const authorInfo: React.CSSProperties = {
    margin: 'auto',
    color: itemColor,
    fontSize: '.8em',
    padding: '5px'
  }

  const itemImageDivStyle: React.CSSProperties = {
    maxHeight: "60%",
    overflowY: "hidden"
  }

  const itemImageStyle: React.CSSProperties = {
    maxWidth: "100%"
  }

  const authorNameStyle: React.CSSProperties = {
    color: '#deaf41'
  }

  const bookReviewTitleStyle: React.CSSProperties = {
    fontWeight: 'bold'
  }

  if (baseCarouselList.length === 0) {
    return (<div></div>)
  }

  return (
      <Carousel dynamicNumber gridWidth={gridWidth} gridBackground={gridBackground} gap={gridGap} margin={gridMargin} padding="25px 0px 25px 10px">
      {baseCarouselList.map((bookReview, index) => {
        return (
          <NavLink key={index} to={`BookReviews/${bookReview.id}`} style={anchorStyle}>
            <div style={itemStyle}>
              {bookReview.bookReviewImage &&
                <div style={itemImageDivStyle}>
                  <img src={bookReview.bookReviewImage} alt={bookReview.title} style={itemImageStyle} />
                </div>
              }
              <div style={authorInfo}>
                <span style={authorNameStyle}>{bookReview.author}</span> <br />
                <span style={bookReviewTitleStyle}>{bookReview.title}</span>
              </div>
            </div>
          </NavLink>
        )
      })}
      </Carousel>)
}

export default BookReviewCarousel;