import { useQueries, QueryClient } from 'react-query';
import axios, { AxiosError, RawAxiosRequestHeaders } from 'axios';
import { useParams, NavLink, Navigate } from 'react-router-dom';
import { IUser } from './IUser';
import { debounce } from 'lodash';
import { useAuth0 } from '@auth0/auth0-react';
import { ClipLoader } from 'react-spinners';
import { useState } from 'react';
import SpinnerModal from 'Components/SpinnerModal';
import * as _ from 'lodash';
import { IImage } from 'Admin/Images/IImage';

const returnHome = (
  <NavLink to="/Admin/Users">
    Usu&aacute;rios
  </NavLink>
)

const queryClient = new QueryClient()

const pageStyle: React.CSSProperties = {
  textAlign: 'left'
}

const gridStyle: React.CSSProperties = {
  display: 'grid',
  gridTemplateColumns: '200px auto',
  maxWidth: '1000px',
  columnGap: '1em',
  rowGap: '.5em',
  fontSize: '1.5em'
}

const authorPhotoDivStyle: React.CSSProperties = {
  maxWidth: '50px'
}

const authorPhotoStyle: React.CSSProperties = {
  maxWidth: '100%'
}

const rolesUlStyle: React.CSSProperties = {
  listStyle: "none",
  marginTop: "0px",
  marginLeft: "-4px",
  paddingLeft: "0px"
}

function SingleUserAdminPage() {
  let { id } = useParams()
  const { user, getAccessTokenSilently, isLoading: auth0Loading } = useAuth0();
  const initialValues: IUser = {
    id: '',
    userName: ''
  }
  const [values, setValues] = useState(initialValues);
  const [saving, setSaving] = useState(false);
  const [authorPhotos, setAuthorPhotos] = useState<{ display: string, value: string, imageType?: string }[]>([]);
  const [initialUser, setInitialUser] = useState(initialValues)
  const [spinnerDialogText, setSpinnerDialogText] = useState("");

  const loadUser = async () => {
    let header: RawAxiosRequestHeaders = {
      'Content-Type': 'application/json'
    }
    const accessToken = await getAccessTokenSilently();
    header.Authorization = `Bearer ${accessToken}`;
    const userURL = encodeURI(`${process.env.REACT_APP_BACKEND_URL}users/${id}`);
    console.log('user url', userURL)
    const data = await (await axios.get<any>(userURL, {
      headers: header
    })).data
    console.log('user data', data)
    const user: IUser = {
      id: data.user.user_id,
      userName: data.user.name,
      userImage: data.user.user_metadata?.picture || data.user.picture,
      userRoles: data.user.roles.map((x: {
        id: string,
        name: string,
        description: string
      }) => x.name)

    }
    setValues(user)
    setInitialUser(user)

    if (authorPhotos.length > 0 && user.userImage) {
      if (!authorPhotos.some(x => x.value === user.userImage)) {
        authorPhotos.push({ value: user.userImage, display: user.userImage })
        setAuthorPhotos(authorPhotos);
      }
    }
    return user
  }

  const getImages = async () => {
    let header: RawAxiosRequestHeaders = {
      'Content-Type': 'application/json'
    }
    const accessToken = await getAccessTokenSilently();
    header.Authorization = `Bearer ${accessToken}`;
    const imageUrl = `${process.env.REACT_APP_BACKEND_URL}images`;
    console.log('imageUrl', imageUrl)
    const data = await (await axios.get<any>(imageUrl, {
      headers: header
    })).data
    console.log('image data', data)
    const mappedImages: { display: string, value: string, imageType?: string }[] =
      data.images.filter((x: IImage) => !x.hidden).map((x: IImage) => {
        return {
          display: x.name,
          value: x.imageUrl,
          imageType: x.imageType
        }
      })

    const authorPics = mappedImages.filter(x => x.imageType === 'userPhoto')
    if (initialUser.userImage && !authorPics.some(x => x.value === initialUser.userImage)) {
      authorPics.push({ value: initialUser.userImage, display: initialUser.userImage })
    }
    setAuthorPhotos(authorPics);
    return data;
  }

  const handleChange = (e: (React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement> | React.ChangeEvent<HTMLSelectElement>)) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value
    })
  }

  const isFormValid = () => {
    return (values.userName.length > 0 && valuesHaveChanged());
  }

  const valuesHaveChanged = () => {
    return (values.userImage !== initialUser.userImage || values.userName !== initialUser.userName || !_.isEqual(values.userRoles?.sort(), initialUser.userRoles?.sort()))
  }
  const handleBlur = (e: (React.FocusEvent<HTMLInputElement> | React.FocusEvent<HTMLTextAreaElement>)) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value
    })
  }

  const handleCheckboxClicked = (e: (React.ChangeEvent<HTMLInputElement>)) => {
    let rolesArray = values.userRoles || []
    if (e.target.checked) {
      rolesArray.push(e.target.value)
    } else {
      rolesArray = rolesArray.filter(x => x !== e.target.value)
    }
    setValues({
      ...values,
      userRoles: rolesArray
    })
  }
  const debouncedHandler = debounce(handleChange, 500);

  const save = async () => {
    setSpinnerDialogText("Salvando as mudan&ccedil;as no usuario")
    const userUrl = `${process.env.REACT_APP_BACKEND_URL}users/${id}`;
    setSaving(true)
    const accessToken = await getAccessTokenSilently();
    let header: RawAxiosRequestHeaders = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
    const patchBody: IUser = values;
    axios.patch(userUrl, patchBody, {
      headers: header
    }).then(res => {
      console.log(res)
      queryClient.removeQueries(['editUser', id], { exact: true })
      setInitialUser(values)
      alert('save successful')
    }).catch((err: AxiosError | Error) => {
      if (axios.isAxiosError(err)) {
        console.error(err.response?.data);
      }
      alert('save failed')
    }).finally(() => {
      setSaving(false)
    })
  }

  const [userQuery, imagesQuery] = useQueries([
    {
      queryKey: ['editUser', id],
      queryFn: loadUser,
      enabled: !auth0Loading,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false
    },
    {
      queryKey: ['images'],
      queryFn: getImages,
      enabled: !auth0Loading
    }
  ]
  )
  if (user && !user['peloestudo/roles'].includes('Owner')) {
    return (<Navigate to="/" />)
  }

  if (userQuery.status === 'loading' || imagesQuery.status === 'loading') {
    return <div><ClipLoader /></div>
  }

  if (userQuery.status === 'error' || imagesQuery.status === 'error') {
    const errors: any[] = [];
    if (userQuery.error) {
      if (axios.isAxiosError(userQuery.error)) {
        errors.push(userQuery.error.code)
        errors.push(userQuery.error.config)
        errors.push(userQuery.error.request)
        errors.push(userQuery.error.response)
        if (userQuery.error.response?.status === 404) {
          return (<div>
            User Not Found.
            <br />
            {returnHome}
          </div>)
        }
      }
      errors.push(userQuery.error)
    }
    if (imagesQuery.status === 'error') {
      errors.push(imagesQuery.error)
    }
    console.error(errors)
    return <div>Error loading User</div>
  }

  if (userQuery.data !== undefined && imagesQuery.data !== undefined) {
    return (
      <section style={pageStyle}>
        <h2>Administrar Usu&aacute;rio</h2>
        <div style={gridStyle}>
          <label>Nome do Usu&aacute;rio:
          </label>
          <input
            type="text"
            name="userName"
            onChange={debouncedHandler}
            onBlur={handleBlur}
            defaultValue={values.userName}
          />
          {authorPhotos.length > 0 &&
            <><label>Foto do Usu&aacute;rio</label>
              <div>
                {values.userImage &&
                  <div style={authorPhotoDivStyle}>
                    <img src={values.userImage} alt={`${values.userImage}`} style={authorPhotoStyle} />
                  </div>
                }
                <select value={values.userImage} name="userImage" onChange={handleChange}>
                  <option></option>
                  {authorPhotos.map(x => {
                    return <option value={x.value}>{x.display}</option>
                  })}
                </select>
              </div></>
          }
          <label>Papeis</label>
          <div>
            <ul style={rolesUlStyle}>
              <li><input type="checkbox" value="Owner" name="roles" checked={values.userRoles?.includes("Owner")} onChange={handleCheckboxClicked} /> <label>Owner</label></li>
              <li><input type="checkbox" value="Admin" name="roles" checked={values.userRoles?.includes("Admin")} onChange={handleCheckboxClicked} /> <label>Admin</label></li>
              <li><input type="checkbox" value="Partner" name="roles" checked={values.userRoles?.includes("Partner")} onChange={handleCheckboxClicked} /> <label>Partner</label></li>
            </ul>

          </div>
        </div>
        <div>
          <button onClick={save} disabled={!isFormValid() || saving}>Submit</button>
        </div>

        {returnHome}
        {
          saving &&
          <SpinnerModal
            spinnerText={spinnerDialogText}
          />
        }
      </section >
    )
  }
  return <></>
}

export default SingleUserAdminPage;