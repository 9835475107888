import { useState } from 'react';
import { debounce } from 'lodash';
import axios, { AxiosError, RawAxiosRequestHeaders } from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import SpinnerModal from 'Components/SpinnerModal';
import { Navigate, useParams } from 'react-router-dom';
import { ISocialMediaPartner, networks } from './ISocialMediaPartner';
import { useQuery } from 'react-query';
import { ClipLoader } from 'react-spinners';

const pageStyle: React.CSSProperties = {
  textAlign: 'left'
}

const gridStyle: React.CSSProperties = {
  display: 'grid',
  gridTemplateColumns: '200px auto',
  maxWidth: '1000px',
  columnGap: '1em',
  rowGap: '.5em',
  fontSize: '1.5em'
}

function EditSocialMediaPartner() {
  let { id } = useParams();
  const partnerURL = `${process.env.REACT_APP_BACKEND_URL}socialMediaPartners/${id}`;
  const { user, getAccessTokenSilently, isLoading: auth0Loading } = useAuth0();
  const initialValues = {
    url: '',
    description: '',
    name: ''
  } as ISocialMediaPartner
  const [values, setValues] = useState(initialValues);
  const [initialPartner, setInitialPartner] = useState(initialValues)
  const [saving, setSaving] = useState(false);

  const loadPartner = async () => {
    let header: RawAxiosRequestHeaders = {
      'Content-Type': 'application/json'
    }
    const accessToken = await getAccessTokenSilently();
    header.Authorization = `Bearer ${accessToken}`;

    const data = await (await axios.get<any>(partnerURL, {
      headers: header
    })).data
    const partner: ISocialMediaPartner = {
      id: data.id,
      url: data.linkUrl,
      description: data.description,
      mediaType: data.network,
      name: data.name
    }
    setValues(partner)
    setInitialPartner(partner)
    return partner
  }

  const handleChange = (e: (React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement> | React.ChangeEvent<HTMLSelectElement>)) => {
    console.log('handleChange', e)
    setValues({
      ...values,
      [e.target.name]: e.target.value
    })
  }

  const isFormValid = () => {
    return (values.name.length > 0 && values.url.length > 0 && values.mediaType && values !== initialPartner)
  }
  const handleBlur = (e: (React.FocusEvent<HTMLInputElement> | React.FocusEvent<HTMLTextAreaElement>)) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value
    })
  }
  const debouncedHandler = debounce(handleChange, 500);
  const save = async () => {
    setSaving(true)
    const accessToken = await getAccessTokenSilently();
    let header: RawAxiosRequestHeaders = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
    const putBody: {
      linkUrl: string,
      description?: string,
      name: string,
      network: string
    } = {
      linkUrl: values.url,
      description: values.description,
      name: values.name,
      network: values.mediaType.toString()
    }
    axios.put(partnerURL, putBody, {
      headers: header
    }).then(res => {
      console.log(res)
      alert('save successful')
    }).catch((err: AxiosError | Error) => {
      if (axios.isAxiosError(err)) {
        console.error(err.response?.data);
      }
      alert('save failed')
    }).finally(() => {
      setSaving(false)
    })
  }

  const { status, data, error } = useQuery('partner', loadPartner, {
    enabled: (!auth0Loading && user?.['peloestudo/roles'].includes('Owner'))
  })

  if (!user?.['peloestudo/roles'].includes('Owner')) {
    return (<Navigate to="/SocialMediaPartners" />)
  }

  if (status === 'loading') {
    return (
      <div>
        <ClipLoader />
      </div>
    )
  }

  if (status === 'error') {
    console.log(error)
    return <div>Error loading Social Media Partners</div>
  }

  if (data !== undefined) {
    return (
      <section style={pageStyle}>
        <h2>Cria Novo Parceiro</h2>
        <div style={gridStyle}>
          <label>Nome do Parceiro:
          </label>
          <input
            type="text"
            name="name"
            onChange={debouncedHandler}
            onBlur={handleBlur}
            defaultValue={values.name}
          />
          <label>Endere&#231;o do Parceiro:
          </label>
          <input
            type="text"
            name="url"
            onChange={debouncedHandler}
            defaultValue={values.url}
            onBlur={handleBlur}
          />
          <label>Descri&#231;&atilde;o:</label>
          <textarea name="description" onBlur={handleBlur} onChange={debouncedHandler} defaultValue={values.description} />
          <label>Rede</label>
          <select name='mediaType' onChange={handleChange}>
            <option></option>
            <option value={networks.Facebook}>{networks.Facebook}</option>
            <option value={networks.Instagram}>{networks.Instagram}</option>
            <option value={networks.Twitter}>{networks.Twitter}</option>
            <option value={networks.Youtube}>{networks.Youtube}</option>
          </select>
        </div>
        <button onClick={save} disabled={!isFormValid() || saving}>Submit</button>
        {
          saving &&
          <SpinnerModal
            spinnerText="Salvando Rede"
          />
        }
      </section>
    );
  }

  return <></>


}

export default EditSocialMediaPartner;