import { IUser } from "./IUser";
import { NavLink } from 'react-router-dom';
interface UserProps {
  items: IUser[]
}

const UserList = ({ items }: UserProps) => {
  if (items.length === 0) {
    return <div></div>
  }
  return (
    <ul>
      {items.map((user: IUser, index: number) => {
        return (
          <li key={index}>
            <p>
              <NavLink to={`${user.id}`}>
                {user.userName}
              </NavLink>
            </p>
          </li>
        )
      })}
    </ul>
  )
}

export default UserList