import { useState } from "react";
import { IAboutUsUser } from "./IAboutUsUser";
import AboutUsUserDisplay from "./AboutUsUserDisplay"

interface AboutUsUserProps {
  items: IAboutUsUser[]
}

const OrganizedAboutUsUserList = ({ items }: AboutUsUserProps) => {
  const [adminUsers] = useState(items.filter(x => x.type === 'Admin'))
  const [columnistUsers] = useState(items.filter(x => x.type === 'Columnist'))

  if (items.length === 0) {
    return <div></div>
  }
  
  return (
    <>
      {adminUsers.length > 0 &&
        <>
          <h3>Administradores</h3>
          {adminUsers.map((user: IAboutUsUser, index: number) => {
            return (
              <AboutUsUserDisplay user={user} />
            )
          })}
        </>
      }
      {columnistUsers.length > 0 &&
        <>
          <h3>Colunistas</h3>
          <ul>
            {columnistUsers.map((user: IAboutUsUser, index: number) => {
              return (
                <AboutUsUserDisplay user={user} />
              )
            })}
          </ul>
        </>
      }
    </>

  )
}

export default OrganizedAboutUsUserList