import { Editor } from '@tinymce/tinymce-react';
import { Editor as TinyMCEEditor } from 'tinymce';
import { useQueries, QueryClient } from 'react-query';
import axios, { AxiosError, RawAxiosRequestHeaders } from 'axios';
import { Navigate, useParams, useNavigate, NavLink } from 'react-router-dom';
import { INewsItem } from './INewsItem';
import { ClipLoader } from 'react-spinners';
import { debounce } from 'lodash';
import { useAuth0 } from '@auth0/auth0-react';
import { useRef, useState } from 'react';
import SpinnerModal from 'Components/SpinnerModal';
import { IImage } from 'Admin/Images/IImage';

const returnHome = (
  <NavLink to="/News">
    Notícias
  </NavLink>
)

const queryClient = new QueryClient()

const pageStyle: React.CSSProperties = {
  textAlign: 'left'
}

const buttonDiv: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between'
}

const gridStyle: React.CSSProperties = {
  display: 'grid',
  gridTemplateColumns: '200px auto',
  maxWidth: '1000px',
  columnGap: '1em',
  rowGap: '.5em',
  fontSize: '1.5em'
}
const newsImageStyle: React.CSSProperties = {
  maxWidth: '100px',
  width: '100%'
}

function EditNewsItem() {
  const navigate = useNavigate();
  let { id } = useParams()
  const { user, getAccessTokenSilently, isLoading: auth0Loading } = useAuth0();

  const initialValues: INewsItem = {
    title: '',
    content: '',
    itemImage: ''
  }


  const [values, setValues] = useState(initialValues);
  const [saving, setSaving] = useState(false);
  const editorRef = useRef<TinyMCEEditor | null>(null)
  const [newsItemPhotos, setNewsItemPhotos] = useState<{ display: string, value: string, imageType?: string }[]>([]);
  const [initialNewsItem, setInitialNewsItem] = useState(initialValues)
  const [spinnerDialogText, setSpinnerDialogText] = useState("");

  const loadNewsItem = async () => {
    let header: RawAxiosRequestHeaders = {
      'Content-Type': 'application/json'
    }
    const newsURL = `${process.env.REACT_APP_BACKEND_URL}news/${id}`;
    const data = await (await axios.get<any>(newsURL, {
      headers: header
    })).data
    const newsItem: INewsItem = {
      title: data.newsItem.title,
      content: data.newsItem.content,
      dateSubmitted: new Date(data.newsItem.dateSubmitted),
      itemImage: data.newsItem.itemImage
    }

    setValues(newsItem)
    setInitialNewsItem(newsItem)

    if (newsItemPhotos.length > 0 && newsItem.itemImage) {
      if (!newsItemPhotos.some(x => x.value === newsItem.itemImage)) {
        newsItemPhotos.push({ value: newsItem.itemImage, display: newsItem.itemImage })
      }
    }


    return newsItem
  }

  const getImages = async () => {
    let header: RawAxiosRequestHeaders = {
      'Content-Type': 'application/json'
    }
    const accessToken = await getAccessTokenSilently();
    header.Authorization = `Bearer ${accessToken}`;
    const imageUrl = `${process.env.REACT_APP_BACKEND_URL}images`;
    const data = await (await axios.get<any>(imageUrl, {
      headers: header
    })).data
    const mappedImages: { display: string, value: string, imageType?: string }[] =
      data.images.filter((x: IImage) => !x.hidden).map((x: IImage) => {
        return {
          display: x.name,
          value: x.imageUrl,
          imageType: x.imageType
        }
      })

    const newsPics = mappedImages.filter(x => x.imageType === 'newsPhoto')
    if (values.itemImage && !newsPics.some(x => x.value === values.itemImage)) {
      newsPics.push({ value: values.itemImage, display: values.itemImage })
    }
    setNewsItemPhotos(newsPics);
    return data;
  }

  const handleChange = (e: (React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement> | React.ChangeEvent<HTMLSelectElement>)) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value
    })
  }
  const isFormValid = () => {
    return (values.title.length > 0 && values.content.length > 0 && values !== initialNewsItem)
  }
  const handleBlur = (e: (React.FocusEvent<HTMLInputElement> | React.FocusEvent<HTMLTextAreaElement>)) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value
    })
  }
  const handleEditorChange = (newValue: string) => {
    setValues({
      ...values,
      content: newValue
    })
  }
  const debouncedHandler = debounce(handleChange, 500);

  const save = async () => {
    setSpinnerDialogText("Salvando as mudan&ccedil;as na notícia")
    const newsItemURL = `${process.env.REACT_APP_BACKEND_URL}news/${id}`;
    setSaving(true)
    const accessToken = await getAccessTokenSilently();
    let header: RawAxiosRequestHeaders = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
    const putBody: INewsItem = values;
    axios.put(newsItemURL, putBody, {
      headers: header
    }).then(res => {
      console.log(res)
      queryClient.removeQueries(['editnewsitem', id], { exact: true })
      alert('save successful')
    }).catch((err: AxiosError | Error) => {
      if (axios.isAxiosError(err)) {
        console.error(err.response?.data);
      }
      alert('save failed')
    }).finally(() => {
      setSaving(false)
    })
  }

  const deleteNewsItem = async () => {
    const newsItemURL = `${process.env.REACT_APP_BACKEND_URL}news/${id}`;
    setSpinnerDialogText("Apagando a notícia")
    setSaving(true)
    const accessToken = await getAccessTokenSilently();
    let header: RawAxiosRequestHeaders = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
    axios.delete(newsItemURL, {
      headers: header
    }).then(res => {
      console.log(res)
      queryClient.removeQueries(['editnewsitem', id], { exact: true })
      alert('A notícia foi apagado')
      navigate("/news")
    }).catch((err: AxiosError | Error) => {
      if (axios.isAxiosError(err)) {
        console.error(err.response?.data);
      }
      alert('erro apagando o artigo')
    }).finally(() => {
      setSaving(false)
    })
  }

  const [newsQuery, imagesQuery] = useQueries([
    {
      queryKey: ['editnewsitem', id],
      queryFn: loadNewsItem,
      enabled: !auth0Loading,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false
    },
    {
      queryKey: ['images'],
      queryFn: getImages,
      enabled: !auth0Loading
    }
  ]
  )

  if (user && !user['peloestudo/roles'].includes('Owner')) {
    return (<Navigate to="/" />)
  }

  if (newsQuery.status === 'loading' || imagesQuery.status === 'loading') {
    return <div><ClipLoader /></div>
  }

  if (newsQuery.status === 'error' || imagesQuery.status === 'error') {
    const errors: any[] = [];
    if (newsQuery.error) {
      if (axios.isAxiosError(newsQuery.error)) {
        if (newsQuery.error.response?.status === 404) {
          console.error('single news item is a 404')
          return <div>
            Not&iacute;cia not found.
            <br />
            {returnHome}
          </div>
        }
        console.error('single news item axios not 404', newsQuery.error.response?.status)
      }
      errors.push(newsQuery.error)
    }
    if (imagesQuery.status === 'error') {
      errors.push(imagesQuery.error)
    }
    console.log(errors)
    return <div>Error loading Not&iacute;cia</div>
  }

  if (newsQuery.data !== undefined) {
    return (
      <section style={pageStyle}>
        <h2>Envie Seu Artigo Aqui</h2>
        <div style={gridStyle}>
          <label>T&iacute;tulo da Not&iacute;cia:
          </label>
          <input
            type="text"
            name="title"
            onChange={debouncedHandler}
            onBlur={handleBlur}
            defaultValue={values.title}
          />
          {
            newsItemPhotos.length > 0 &&
            (<><label>Imag&eacute;m da Not&iacute;cia</label>
              <div>
                {values.itemImage &&
                  <div>
                    <img src={values.itemImage} alt={values.title} style={newsImageStyle} />
                  </div>
                }
                <select value={values.itemImage} name="itemImage" onChange={handleChange}>
                  {!values.itemImage &&
                    <option></option>
                  }
                  {newsItemPhotos.map(x => {
                    return <option value={x.value}>{x.display}</option>
                  })}
                </select>
              </div>
            </>)
          }
        </div>

        <Editor
          apiKey='c27sqmj1ce4fnrsz5n0bcciljvxysmhsu5mjm3jzxdr24amf'
          onInit={(evt, editor) => editorRef.current = editor}
          onEditorChange={(newValue, editor) => handleEditorChange(newValue)}
          value={values.content}
          init={{
            height: 500,
            plugins: 'preview code',
            toolbar: [
              { name: 'history', items: ['undo', 'redo'] },
              { name: 'styles', items: ['styles'] },
              { name: 'formatting', items: ['bold', 'italic', 'blockquote'] },
              { name: 'alignment', items: ['alignleft', 'aligncenter', 'alignright', 'alignjustify', 'alignnone'] },
              { name: 'indentation', items: ['outdent', 'indent'] },
              { name: 'preview', items: ['preview', 'code'] }
            ]
          }}
        />
        <div style={buttonDiv}>
          <button onClick={save} disabled={!isFormValid() || saving}>Submit</button>
          <button onClick={() => {
            if (window.confirm("Tem certeza que quer apagar? Não vai poder recuperar.")) { deleteNewsItem() }
          }} disabled={saving}>Apagar</button>
        </div>
        {
          saving &&
          <SpinnerModal
            spinnerText={spinnerDialogText}
          />
        }
      </section>
    )
  }
  return <></>
}

export default EditNewsItem;