import { IAdvertisement } from "./IAdvertisement";
import Carousel from "widgets/Carousel"

interface AdvertisementProps {
  items: IAdvertisement[]
}



const AdvertisementCarousel = ({items}: AdvertisementProps) => {
  const anchorStyle: React.CSSProperties = {
    display: 'block',
    width: "100%",
    height: "100%",
    minWidth: "100%"
  }
  
  const itemStyle: React.CSSProperties = {
    display: 'block',
    objectFit: 'cover',
    width: '100%',
    height: '100%',
    aspectRatio: '1/ 1'
  }
  
  if (items.length === 0) {
    return (<div></div>)
  }

  return (
  <Carousel>
    {items.map(item => {
      return (
        <a href={item.linkURL} style={anchorStyle}><img src={item.adPhotoURL} alt={item.companyName} style={itemStyle} /></a>
      )
    })}
  </Carousel>)
}

export default AdvertisementCarousel;