import { useQuery } from 'react-query';
import axios from 'axios';
import { InstagramItem } from "./InstagramItem";
import InstagramCarousel from './InstagramCarousel';
import { useState } from 'react';
import { ClipLoader } from 'react-spinners';

const userId = process.env.REACT_APP_INSTAGRAM_USER_ID;
const accessCode = process.env.REACT_APP_INSTAGRAM_ACCESS_CODE;

const instagramUrl = `https://graph.instagram.com/${userId}/media?access_token=${accessCode}&fields=media_url,permalink,media_type`;
const InstagramFeed = () => {
  const [reloadFocus, setReloadFocus] = useState(true);
  const loadInstaFeed = async () => {
    const data = await (await axios.get<any>(instagramUrl)).data
    let items:InstagramItem[] = []
    for (let i = 0 ; i <= data.data.length && i < 9; i ++){
      let item = data.data[i];
      let mapped: InstagramItem = {
        permaLink: item.permalink,
        mediaUrl: item.media_url,
        mediaType: item.media_type
      }
      items.push(mapped)
    }
    return items;
  }

  const {status, data, error} = useQuery('instagramPosts', loadInstaFeed, {
    refetchOnWindowFocus: reloadFocus,  
    retry: (failureCount, error: any) => {
      if (axios.isAxiosError(error)){
        if (error.response?.status === 400 && error.response.data.error.type === "OAuthException") {
          setReloadFocus(false)
          return false
        }
      }
      return (failureCount < 3)
    }
  })

  if (status === 'loading') {
    return <div><ClipLoader /></div>
  }

  if (status === 'error') {
    console.log(error)
    return <div>Error loading data</div>
  }

  if (data !== undefined) {
    return (
      <InstagramCarousel items={data}></InstagramCarousel>
    )
  }
  return <></>
  
}

export default InstagramFeed