import { InstagramItem } from "./InstagramItem";
import Carousel from "widgets/Carousel"

interface InstagridProps {
  items: InstagramItem[]
}



const InstagramCarousel = ({items}: InstagridProps) => {
  const anchorStyle: React.CSSProperties = {
    display: 'block',
    width: "100%",
    height: "100%",
    minWidth: "100%"
  }
  
  const itemStyle: React.CSSProperties = {
    display: 'block',
    objectFit: 'cover',
    width: '100%',
    height: '100%',
    aspectRatio: '1/ 1'
  }
  
  if (items.length === 0) {
    return (<div></div>)
  }

  return (
  <Carousel maxWidth="150px">
    {items.map(item => {
      if (item.mediaType === 'VIDEO') {
        return (
          <video controls style={itemStyle}>
            <source src={item.mediaUrl} />
          </video>
        )
      }
      return  (
        <a href={item.permaLink} style={anchorStyle}><img src={item.mediaUrl} alt='' style={itemStyle}/></a>
      )
    })}
  </Carousel>)
}

export default InstagramCarousel;