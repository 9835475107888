import { useAuth0 } from '@auth0/auth0-react';
import { Editor } from '@tinymce/tinymce-react';
import axios, { AxiosError, RawAxiosRequestHeaders } from 'axios';
import { debounce } from 'lodash';
import React, { useState, useRef } from "react";
import { NavLink, Navigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { ClipLoader } from 'react-spinners';
import { Editor as TinyMCEEditor } from 'tinymce';
import { IAboutUsUser } from "./IAboutUsUser";
import { IImage } from 'Admin/Images/IImage';
import SpinnerModal from 'Components/SpinnerModal';

const returnHome = (
  <NavLink to="/Admin/AboutUsUsers">
    Nossos Usu&aacute;rios
  </NavLink>
)

const pageStyle: React.CSSProperties = {
  textAlign: 'left'
}

const gridStyle: React.CSSProperties = {
  display: 'grid',
  gridTemplateColumns: '200px auto',
  maxWidth: '1000px',
  columnGap: '1em',
  rowGap: '.5em',
  fontSize: '1.5em'
}

const authorPhotoDivStyle: React.CSSProperties = {
  maxWidth: '50px'
}

const authorPhotoStyle: React.CSSProperties = {
  maxWidth: '100%'
}

function NewAboutUsUserPage() {
  const { user, getAccessTokenSilently, isLoading: auth0Loading } = useAuth0();
  const initialValues: IAboutUsUser = {
    name: '',
    description: '',
    type: 'Columnist'
  }
  const editorRef = useRef<TinyMCEEditor | null>(null)
  const [values, setValues] = useState(initialValues);
  const [saving, setSaving] = useState(false);
  const [userPhotos, setUserPhotos] = useState<{ display: string, value: string, imageType?: string }[]>([]);

  const handleChange = (e: (React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>)) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value
    })
  }

  const isFormValid = () => {
    return (values.name.length > 0 && values.description.length > 0)
  }
  const handleBlur = (e: (React.FocusEvent<HTMLInputElement>)) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value
    })
  }
  const debouncedHandler = debounce(handleChange, 500);

  const handleEditorChange = (newValue: string) => {
    setValues({
      ...values,
      description: newValue
    })
  }

  const getImages = async () => {
    let header: RawAxiosRequestHeaders = {
      'Content-Type': 'application/json'
    }
    const accessToken = await getAccessTokenSilently();
    header.Authorization = `Bearer ${accessToken}`;
    const imageUrl = `${process.env.REACT_APP_BACKEND_URL}images`;
    console.log('imageUrl', imageUrl)
    const data = await (await axios.get<any>(imageUrl, {
      headers: header
    })).data
    console.log('image data', data)
    const mappedImages: { display: string, value: string, imageType?: string }[] =
      data.images.filter((x: IImage) => !x.hidden).map((x: IImage) => {
        return {
          display: x.name,
          value: x.imageUrl,
          imageType: x.imageType
        }
      })

    const userImages = mappedImages.filter(x => x.imageType === 'userPhoto')
    setUserPhotos(userImages);
    return data;
  }

  const save = async () => {
    const userUrl = `${process.env.REACT_APP_BACKEND_URL}aboutUsUsers`;
    setSaving(true)
    const accessToken = await getAccessTokenSilently();
    let header: RawAxiosRequestHeaders = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
    const postBody : {
      name: string,
      type: string,
      description: string,
      photoUrl?: string
    } = {
      name: values.name,
      description: values.description,
      type: values.type
    }
    if (values.photoUrl !== undefined) {
      postBody.photoUrl = values.photoUrl
    }
    axios.post(userUrl, postBody , {
      headers: header
    }).then(res => {
      console.log(res)
      alert('save successful')
    }).catch((err: AxiosError | Error) => {
      if (axios.isAxiosError(err)) {
        console.error(err.response?.data);
      }
      alert('save failed')
    }).finally(() => {
      setSaving(false)
    })
  }

  const { status } = useQuery('aboutUsUsersPhotos', getImages, {
    enabled: (!auth0Loading && user?.['peloestudo/roles'].includes('Owner'))
  })

  if (user && !user['peloestudo/roles'].includes('Owner')) {
    return (<Navigate to="/" />)
  }

  return (
    <section style={pageStyle}>
      <h2>Novo usu&aacute;rio</h2>
      <div style={gridStyle}>
        <label>Nome do usu&aacute;rio</label>
        <input
          type="text"
          name="name"
          onChange={debouncedHandler}
          onBlur={handleBlur}
          defaultValue={values.name}
        />
        <label>Tipo de usu&aacute;rio</label>
        <select value={values.type} name="type" onChange={handleChange}>
          <option value='Admin'>Administrador</option>
          <option value='Columnist'>Colunista</option>
        </select>
        {status === 'loading' &&
          (
            <div><ClipLoader /></div>
          )
        }
        {
          status === 'error' &&
          (
            <div>Error loading images</div>
          )
        }
        {
          userPhotos.length > 0 &&
          <>
            <label>Foto do Usu&aacute;rio</label>
            <div>
              {values.photoUrl &&
                <div style={authorPhotoDivStyle}>
                  <img src={values.photoUrl} alt={`${values.photoUrl}`} style={authorPhotoStyle} />
                </div>
              }
              <select value={values.photoUrl} name="photoUrl" onChange={handleChange}>
                <option></option>
                {userPhotos.map(x => {
                  return <option value={x.value}>{x.display}</option>
                })}
              </select>
            </div>
          </>
        }
      </div >

      <Editor
        apiKey='c27sqmj1ce4fnrsz5n0bcciljvxysmhsu5mjm3jzxdr24amf'
        onInit={(evt, editor) => editorRef.current = editor}
        onEditorChange={(newValue, editor) => handleEditorChange(newValue)}
        value={values.description}
        init={{
          height: 500,
          plugins: 'preview code',
          toolbar: [
            { name: 'history', items: ['undo', 'redo'] },
            { name: 'styles', items: ['styles'] },
            { name: 'formatting', items: ['bold', 'italic', 'blockquote'] },
            { name: 'alignment', items: ['alignleft', 'aligncenter', 'alignright', 'alignjustify', 'alignnone'] },
            { name: 'indentation', items: ['outdent', 'indent'] },
            { name: 'preview', items: ['preview', 'code'] }
          ]
        }}
      />
      <button onClick={save} disabled={!isFormValid() || saving}>Submit</button>

      {returnHome}
      {
        saving &&
        <SpinnerModal
          spinnerText="Salvando usu&aacute;rio"
        />
      }
    </section >
  )
}

export default NewAboutUsUserPage;