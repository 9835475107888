import './App.css';
import HomePage from './Home/HomePage';
import ArticlePage from './Articles/ArticlePage';
import SingleArticle from './Articles/SingleArticle';
import EditArticle from './Articles/EditArticle';
import YoutubePage from './Youtube/YoutubePage';
import SocialMediaWidget from './widgets/SocialMedia';
import BookPage from './Books/BookPage';
import SingleBook from './Books/SingleBook';
import { AuthenticationGuard } from "./Components/Authentication-Guard";
import { Routes, Route } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query'
import NewArticle from './Articles/NewArticle';
import HeaderComponent from './Header/HeaderComponent';
import InstagramFeed from 'Instagram/InstagramFeed';
import AdminPage from 'Admin/AdminPage';
import PictureAdminPage from 'Admin/Images/PictureAdminPage';
import HidePicturePage from 'Admin/Images/HidePicturePage';
import UploadPicturePage from 'Admin/Images/UploadPicturePage';
import UsersAdminPage from 'Admin/Users/UsersAdminPage';
import SingleUserAdminPage from 'Admin/Users/SingleUserAdminPage';
import { HelmetProvider } from 'react-helmet-async';
import { Footer } from './Components/Footer';
import SocialMediaPartnersPage from 'SocialMediaPartners/SocialMediaPartnersPage';
import NewSocialMediaPartner from 'SocialMediaPartners/NewSocialMediaPartner';
import EditSocialMediaPartner from 'SocialMediaPartners/EditSocialMediaPartner';
import AdvertisementAdminPage from './Admin/Advertisement/AdvertisementsAdminPage';
import SingleAdvertisementAdminPage from './Admin/Advertisement/SingleAdvertisementAdminPage';
import NewAdvertisementAdminPage from './Admin/Advertisement/NewAdvertisementAdminPage';
import NewsPage from './NewsItem/NewsPage';
import NewNewsItem from './NewsItem/NewNewsItem';
import SingleNewsItem from 'NewsItem/SingleNewsItem';
import EditNewsItem from 'NewsItem/EditNewsItem';
import BookReviewPage from 'BookReviews/BookReviewPage';
import NewBookReview from 'BookReviews/NewBookReview';
import SingleBookReview from 'BookReviews/SingleBookReview';
import EditBookReview from 'BookReviews/EditBookReview';
import AboutUsUserAdminPage from 'AboutUsUsers/AboutUsUsersAdminPage';
import NewAboutUsUsersPage from 'AboutUsUsers/NewAboutUsUserPage';
import EditAboutUsUserPage from 'AboutUsUsers/EditAboutUsUserPage';
import AboutPage from 'AboutUsUsers/AboutPage';

const queryClient = new QueryClient()

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <HelmetProvider>
        <div className="App">
          <HeaderComponent />
          <div className="container">
            <div className="mainContent">
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/Articles" element={<ArticlePage />} />
                <Route path="/Articles/submit" element={<AuthenticationGuard component={NewArticle} />} />
                <Route path="/Articles/:id" element={<SingleArticle />} />
                <Route path="/Articles/:id/edit" element={<AuthenticationGuard component={EditArticle} />} />
                <Route path="/Youtube" element={<YoutubePage />} />
                <Route path="/Books" element={<BookPage />} />
                <Route path="/Books/:id" element={<SingleBook />} />
                <Route path="/Admin" element={<AuthenticationGuard component={AdminPage} />} />
                <Route path="/Admin/Pictures" element={<AuthenticationGuard component={PictureAdminPage} />} />
                <Route path="/Admin/Pictures/Hide" element={<AuthenticationGuard component={HidePicturePage} />} />
                <Route path="/Admin/Pictures/New" element={<AuthenticationGuard component={UploadPicturePage} />} />
                <Route path="/Admin/Users" element={<AuthenticationGuard component={UsersAdminPage} />} />
                <Route path="/Admin/Users/:id" element={<AuthenticationGuard component={SingleUserAdminPage} />} />
                <Route path="/Admin/AboutUsUsers" element={<AuthenticationGuard component={AboutUsUserAdminPage} />} />
                <Route path="/Admin/AboutUsUsers/New" element={<AuthenticationGuard component={NewAboutUsUsersPage} />} />
                <Route path="/Admin/AboutUsUsers/:id" element={<AuthenticationGuard component={EditAboutUsUserPage} />} />
                <Route path="/SocialMediaPartners" element={<SocialMediaPartnersPage />} />
                <Route path="/SocialMediaPartners/new" element={<AuthenticationGuard component={NewSocialMediaPartner} />} />
                <Route path="/SocialMediaPartners/:id" element={<AuthenticationGuard component={EditSocialMediaPartner} />} />
                <Route path="/Admin/Advertisements" element={<AuthenticationGuard component={AdvertisementAdminPage} />} />
                <Route path="/Admin/Advertisements/new" element={<AuthenticationGuard component={NewAdvertisementAdminPage} />} />
                <Route path="/Admin/Advertisements/:id" element={<AuthenticationGuard component={SingleAdvertisementAdminPage} />} />
                <Route path="/News" element={<NewsPage />} />
                <Route path="/News/Submit" element={<AuthenticationGuard component={NewNewsItem} />} />
                <Route path="/News/:id" element={<SingleNewsItem />} />
                <Route path="/News/:id/edit" element={<AuthenticationGuard component={EditNewsItem} />} />
                <Route path="/BookReviews" element={<BookReviewPage />} />
                <Route path="/BookReviews/:id" element={<SingleBookReview />} />
                <Route path="/BookReviews/submit" element={<AuthenticationGuard component={NewBookReview} />} />
                <Route path="/BookReviews/:id/edit" element={<AuthenticationGuard component={EditBookReview} />} />
                <Route path="/About" element={<AboutPage />} />
              </Routes>
            </div>
            <div className="leftColumn">
              <SocialMediaWidget />
              <InstagramFeed />
            </div>
          </div>
          <Footer />
        </div>
      </HelmetProvider>
    </QueryClientProvider>
  );
}

export default App;
