import UsersFeed from './UsersFeed'
function UsersAdminPage() {
  return (
    <>
      <h2>Selecione Usu&aacute;rio para administrar</h2>
      <UsersFeed />
    </>
    )
}

export default UsersAdminPage;