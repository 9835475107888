import { IComment } from "./IComment";
import { useAuth0 } from "@auth0/auth0-react";
import ReactHtmlParser from 'react-html-parser';
import NewComment from './NewComment';
import React, { useState } from "react";
import { useMediaQuery } from 'react-responsive';
import Moment from 'react-moment';
import axios, { RawAxiosRequestHeaders } from 'axios';
import SpinnerModal from 'Components/SpinnerModal';

interface CommentDisplayProps {
  comment: IComment
  isChild?: Boolean
}

const commentPlusButtons: React.CSSProperties = {
  padding: "10px",
  display: "flex",
  flexDirection: "column"
}

const displayBox: React.CSSProperties = {
  border: "1px solid lightgray"
}

const warningSpan: React.CSSProperties = {
  color: 'red'
}

const adminButtonsDiv: React.CSSProperties = {
  flex: 0
}

const commentContentDiv: React.CSSProperties = {
  flex: 1
}

function CommentDisplay({ comment, isChild }: CommentDisplayProps) {
  const isDesktop = useMediaQuery({
    query: '(min-width: 1224px)'
  })
  if (isChild) {
    displayBox.marginLeft = "10px";
  }
  if (isDesktop) {
    commentPlusButtons.flexDirection = "row";
    adminButtonsDiv.display = "flex";
    adminButtonsDiv.flexDirection = "column";
  }
  const { user, getAccessTokenSilently } = useAuth0();
  const [replyToComment, setReplyToComment] = useState(false);
  const [showDeletedContent, setShowDeletedContent] = useState(false);
  const [saving, setSaving] = useState(false);
  const [spinnerDialogText, setSpinnerDialogText] = useState('');

  const renderReply = () => {
    if (!replyToComment) {
      return (<button onClick={() => setReplyToComment(true)}>Responder Ao coment&aacute;rio</button>)
    } return (
      <NewComment articleId={comment.articleId} parentId={comment.id} />
    )
  }

  const renderViewDeleted = () => {
    const buttonText = showDeletedContent ? "Esconder Comentário" : "Mostrar Comentário Apagado";
    return (
      <>
        <button onClick={restoreComment}>Restorar Coment&aacute;rio</button>
        <button onClick={() => setShowDeletedContent(!showDeletedContent)}>
          {buttonText}
        </button>
      </>
    )
  }

  const renderActiveButtons = () => {
    if (user && user['peloestudo/roles'].includes('Owner')) {
      return (
        <>
          {
            !comment.approved &&
            <button onClick={approveComment}>Aprovar Coment&aacute;rio</button>
          }
          {
            !comment.deleted &&
            <button onClick={deleteComment}>Apagar Coment&aacute;rio</button>
          }
          {
            comment.deleted &&
            renderViewDeleted()
          }
        </>
      )
    } else if (user && user['peloestudo/roles'].includes('Admin')) {
      return (
        <>
          {
            !comment.approved &&
            <button onClick={approveComment}>Aprovar Coment&aacute;rio</button>
          }
        </>
      )
    }
  }

  const renderContentBody = () => {
    if (showDeletedContent && comment.deleted) {
      return (ReactHtmlParser(comment.deletedContent || ''))
    } else {
      return (ReactHtmlParser(comment.content))
    }
  }

  const approveComment = async () => {
    const commentURL = `${process.env.REACT_APP_BACKEND_URL}comments/${comment.id}/approve`;
    setSpinnerDialogText('Aprovando Comentário');
    setSaving(true);
    const accessToken = await getAccessTokenSilently();
    let header: RawAxiosRequestHeaders = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
    try {
      await axios.post<any>(commentURL, {}, {
        headers: header
      })
      alert('Aprovou o comentário');
    } catch (err) {
      if (axios.isAxiosError(err)) {
        console.error(err.response?.data);
      }
      alert('erro approvando o comentário')
    }
    setSaving(false);
  }

  const deleteComment = async () => {
    const commentURL = `${process.env.REACT_APP_BACKEND_URL}comments/${comment.id}`;
    setSpinnerDialogText('Apagando Comentário');
    setSaving(true)
    const accessToken = await getAccessTokenSilently();
    let header: RawAxiosRequestHeaders = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
    try {
      await axios.delete<any>(commentURL, {
        headers: header
      })
      alert('Apagou o comentário');
    } catch (err) {
      if (axios.isAxiosError(err)) {
        console.error(err.response?.data);
      }
      alert('erro appagando o comentário')
    }
    setSaving(false);
  }

  const restoreComment = async () => {
    const commentURL = `${process.env.REACT_APP_BACKEND_URL}comments/${comment.id}/restore`;
    setSpinnerDialogText('Restorando Comentário');
    const accessToken = await getAccessTokenSilently();
    let header: RawAxiosRequestHeaders = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
    try {
      await axios.post<any>(commentURL, {}, {
        headers: header
      })
      alert('Restorou o comentário');
    } catch (err) {
      if (axios.isAxiosError(err)) {
        console.error(err.response?.data);
      }
      alert('erro restaurando o comentário')
    }
    setSaving(false);
  }

  return (
    <div style={displayBox}>
      <div style={commentPlusButtons}>
        <div style={commentContentDiv}>
          {comment.user} | <Moment locale="pt-br" format='lll'>{comment.dateSubmitted}</Moment><br />
          {
            !comment.approved &&
            (
              <><span style={warningSpan}>COMMENT&Aacute;RIO N&Atilde;O APROVADO</span><br /></>
            )
          }
          {renderContentBody()}
        </div>
        <div style={adminButtonsDiv}>
          {renderActiveButtons()}
        </div>
      </div>
      {(user && !!!isChild) &&
        renderReply()
      }
      {(comment.children && comment.children.length > 0) &&
        comment.children.map(childComment => {
          return (
            <CommentDisplay comment={childComment} isChild={true} />
          )
        })
      }
      {
        saving &&
        <SpinnerModal
          spinnerText={spinnerDialogText}
        />
      }
    </div>

  )
}

export default CommentDisplay;