import { useQuery } from 'react-query';
import axios, { RawAxiosRequestHeaders } from 'axios';
import { INewsItem } from './INewsItem';
import NewsItemList from './NewsItemList';
import NewsItemCarousel from './NewsItemCarousel';
import { useAuth0 } from '@auth0/auth0-react';
import ClipLoader from 'react-spinners/ClipLoader';
import React, { FunctionComponent } from 'react';

const newsURL = `${process.env.REACT_APP_BACKEND_URL}news`;
const carouselContainerStyle: React.CSSProperties = {
  backgroundColor: "rgb(62, 92, 152)",
  margin: "0px -10px",
  paddingTop: "15px"
};
const carouselHeaderStyle: React.CSSProperties = {
  color: "white",
  marginTop: 0,
  marginBottom: 0
};
const NewsFeed:FunctionComponent<{carousel?: boolean}> = (props) => {
  const { isLoading: auth0Loading } = useAuth0();
  const loadNewsItems = async () => {
    let header: RawAxiosRequestHeaders = {
      'Content-Type': 'application/json'
    }
    const data = await (await axios.get<any>(newsURL, {
      headers: header
    })).data
    let items: INewsItem[] = data.data.map(
      (item:
        {
          id: string,
          title: string,
          content: string,
          dateSubmitted: string,
          itemImage?: string
        }) => {
        return  {
          id: item.id,
          title: item.title,
          content: item.content,
          dateSubmitted: new Date(item.dateSubmitted),
          itemImage: item.itemImage
        } as INewsItem
      });

    return items
  }

  const { status, data, error } = useQuery('newsItems', loadNewsItems, {
    enabled: !auth0Loading})

  if (status === 'loading') {
    return (
      <div>
        <ClipLoader />
      </div>
    )
  }

  if (status === 'error') {
    console.log(error)
    return <div>Error loading Noticias</div>
  }
  
  if (data !== undefined ) {
    if (props.carousel) {
      return (
        <div style={carouselContainerStyle}>
          <h3 style={carouselHeaderStyle}>Not&iacute;cias</h3>
          <NewsItemCarousel items = {data}></NewsItemCarousel>
        </div>
      )
    }
    return (
      <NewsItemList items={data}></NewsItemList>
    )
  }

  return <></>

}

export default NewsFeed