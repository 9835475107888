import { INewsItem } from "./INewsItem";
import { NavLink } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";

interface NewsItemProps {
  items: INewsItem[]
}

const containerStyle: React.CSSProperties = {
  fontSize: '1.2em'
}

const photoStyle: React.CSSProperties = {
  maxWidth: '750px',
  width: '100%'
}

const NewsItemList = ({ items }: NewsItemProps) => {
  const { user } = useAuth0();
  // const { user } = useAuth0();
  if (items.length === 0) {
    return <div></div>
  }
  return (
    <>
      {items.map((newsItem: INewsItem, index: number) => {
        return (
          <div key={index} style={containerStyle}>
            {newsItem.itemImage &&
              <div>
                <img src={newsItem.itemImage} alt={newsItem.title} style={photoStyle} />
              </div>
            }
            <h3>
              <NavLink to={`${newsItem.id}`}>
                {newsItem.title}
              </NavLink>
              {(user && user['peloestudo/roles'].includes('Owner')) &&
                <NavLink to={`${newsItem.id}/edit`}>
                  Editar
                </NavLink>
              }
            </h3>
          </div>
        )
      })}
    </>
  )
}

export default NewsItemList