import ArticleFeed from 'Articles/ArticleFeed'
import SocialMediaShareWidget from 'widgets/SocialMediaShare';
import BookReviewsFeed from 'BookReviews/BookReviewFeed';

const paragraphStyle: React.CSSProperties = {
  maxWidth: '875px',
  textAlign: 'justify',
  marginTop: '50px'
}

function HomePage() {
  return (
    <>
      <ArticleFeed carousel />
      <div style={paragraphStyle}>
        <p>
          Pelo Estudo e Pela Fé é um projeto de conteúdo multimídia com foco na busca do conhecimento intelectual e espiritual.<br />
          Somos um grupo cristão/sud que tem o objetivo de disseminar conhecimentos através do estudo e pela fé, estimulando o debate e aproximando a Fé ao conhecimento para que aumente o crescimento  fisico e espiritual das pessoas.<br />
          Temos como missão editorial dar voz aos assuntos ligados ao estudo e a fé, seja nas áreas da educação, saúde, religião, cultura, politica, entre outras.
        </p>
        <SocialMediaShareWidget />
      </div>
      <BookReviewsFeed carousel />
    </>
  )
}

export default HomePage;