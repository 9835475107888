import { useQuery } from 'react-query';
import axios, { RawAxiosRequestHeaders } from 'axios';
import { useParams } from 'react-router-dom';
import { IBookReview } from './IBookReview';
import Moment from 'react-moment';
import ReactHtmlParser from 'react-html-parser';
import { NavLink } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { Seo } from 'widgets/Seo';
import SocialMediaShareWidget from 'widgets/SocialMediaShare';

const returnHome = (
  <NavLink to="/BookReviews">
    Resenhas
  </NavLink>
)

const bodyDivStyle: React.CSSProperties = {
  padding: '0 10px'
}

const authorStyle: React.CSSProperties = {
  textAlign: 'left',
  marginBlockEnd: '.75em'
}

const timeStampStyle: React.CSSProperties = {
  fontWeight: 'lighter',
  fontSize: '.75em',
  marginBlockStart: '0'
}

const authorInfoStyle: React.CSSProperties = {
  display: 'flex',
  gap: '50px'
}

const authorPhotoDivStyle: React.CSSProperties = {
  maxWidth: '50px'
}

const authorPhotoStyle: React.CSSProperties = {
  maxWidth: '100%'
}

const headerImageStyle: React.CSSProperties = {
  maxWidth: '750px',
  width: '100%'
}

function SingleBookReview() {
  let { id } = useParams()
  const loadBookReview = async () => {
    let header: RawAxiosRequestHeaders = {
      'Content-Type': 'application/json'
    }
    const bookReviewURL = `${process.env.REACT_APP_BACKEND_URL}bookReviews/${id}`;
    const data = await (await axios.get<any>(bookReviewURL, {
      headers: header
    })).data
    const bookReview: IBookReview = {
      title: data.bookReview.title,
      content: data.bookReview.content,
      dateSubmitted: new Date(data.bookReview.dateSubmitted),
      author: data.bookReview.author,
      header: data.bookReview.header,
      authorPhoto: data.bookReview.authorPhoto,
      bookReviewImage: data.bookReview.bookReviewImage
    }
    return bookReview
  }

  const { status, data, error } = useQuery('bookReview', loadBookReview, {
    refetchOnWindowFocus: false,
    retry: (failureCount, error: any) => {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 404) {
          return false;
        }
      }
      return (failureCount < 3)
    }
  })

  if (status === 'loading') {
    return <div><ClipLoader /></div>
  }

  if (status === 'error') {
    if (axios.isAxiosError(error)) {
      console.error('single book review is an axios error', error)
      if (error.response?.status === 404) {
        console.error('single book review is a 404')
        return <div>
          Book review not found.
          <br />
          {returnHome}
        </div>
      }
      console.error('single book review axios not 404', error.response?.status)
    }
    console.log(error)
    return <div>Error loading book review</div>
  }

  if (data !== undefined) {
    return (
      <div>
        <Seo 
          title={`${process.env.REACT_APP_SITE_TITLE} | ${data.title}`}
          description={data.header}
          type="Article"
          name={data.author}
          image={data.bookReviewImage}
        />
        {data.bookReviewImage &&
          <div>
            <img src={data.bookReviewImage} alt={data.title} style={headerImageStyle} />
          </div>
        }
        <div>
          <h2>{data.title}</h2>
          <p>{data.header}</p>
          <div style={authorInfoStyle}>
            {data.authorPhoto &&
              <div style={authorPhotoDivStyle}>
                <img src={data.authorPhoto} alt={`${data.author}`} style={authorPhotoStyle} />
              </div>
            }
            <div>
              <h4 style={authorStyle}>{data.author}</h4>
              <p style={timeStampStyle}>
                <Moment locale="pt-br" format='lll'>
                  {data.dateSubmitted}
                </Moment>
              </p>
            </div>
          </div>
          <SocialMediaShareWidget />
        </div>
        <div style={bodyDivStyle}>
          {ReactHtmlParser(data.content)}
        </div>
        {returnHome}
      </div>
    )
  }
  return <></>
}

export default SingleBookReview;