import { IArticle } from "./IArticle";
import { NavLink } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import Moment from 'react-moment';

interface ArticleProps {
  items: IArticle[]
}

const warningSpan: React.CSSProperties = {
  color: 'red'
}

const containerStyle: React.CSSProperties = {
  fontSize: '1.2em'
}

const centeredStyle: React.CSSProperties = {
  textAlign: 'center'
}

const photoStyle: React.CSSProperties = {
  maxWidth: '750px',
  width: '100%'
}

const ArticleList = ({ items }: ArticleProps) => {
  const { user } = useAuth0();
  if (items.length === 0) {
    return <div></div>
  }
  return (
    <>
      {items.map((article: IArticle, index: number) => {
        return (
          <div key={index} style={containerStyle}>
            {article.articleImage &&
              <div>
                <img src={article.articleImage} alt={article.title} style={photoStyle} />
              </div>
            }
            <h3>
              <NavLink to={`${article.id}`}>
                {article.title}
              </NavLink>
            </h3>
            <p style={centeredStyle}>
              {`${article.author} | `}
              <Moment locale="pt-br" format='ll'>
                {article.dateSubmitted}
              </Moment>
              {
                !article.approved &&
                <span style={warningSpan}>THIS ARTICLE IS NOT YET APPROVED</span>
              }

              {(user && user['peloestudo/roles'].includes('Owner')) &&
                <NavLink to={`${article.id}/edit`}>
                  Editar
                </NavLink>
              }
            </p>
            <p style={centeredStyle}>
              {article.header}
            </p>
          </div>
        )
      })}
    </>
  )
}

export default ArticleList