import { useAuth0 } from '@auth0/auth0-react';
import { Editor } from '@tinymce/tinymce-react';
import axios, { AxiosError, RawAxiosRequestHeaders } from 'axios';
import { debounce } from 'lodash';
import React, { useState, useRef } from "react";
import { useParams, useNavigate, NavLink, Navigate } from 'react-router-dom';
import { useQueries, QueryClient } from 'react-query';
import { ClipLoader } from 'react-spinners';
import { Editor as TinyMCEEditor } from 'tinymce';
import { IAboutUsUser } from "./IAboutUsUser";
import { IImage } from 'Admin/Images/IImage';
import SpinnerModal from 'Components/SpinnerModal';

const returnHome = (
  <NavLink to="/Admin/AboutUsUsers">
    Nossos Usu&aacute;rios
  </NavLink>
)

const queryClient = new QueryClient()

const pageStyle: React.CSSProperties = {
  textAlign: 'left'
}

const buttonDiv: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between'
}

const gridStyle: React.CSSProperties = {
  display: 'grid',
  gridTemplateColumns: '200px auto',
  maxWidth: '1000px',
  columnGap: '1em',
  rowGap: '.5em',
  fontSize: '1.5em'
}

const authorPhotoDivStyle: React.CSSProperties = {
  maxWidth: '50px'
}

const authorPhotoStyle: React.CSSProperties = {
  maxWidth: '100%'
}

function EditAboutUsUserPage() {
  const navigate = useNavigate();
  let { id } = useParams()
  const { user, getAccessTokenSilently, isLoading: auth0Loading } = useAuth0();
  const initialValues: IAboutUsUser = {
    name: '',
    description: '',
    type: 'Columnist'
  }
  const editorRef = useRef<TinyMCEEditor | null>(null)
  const [initialUser, setInitialUser] = useState(initialValues);
  const [values, setValues] = useState(initialValues);
  const [saving, setSaving] = useState(false);
  const [userPhotos, setUserPhotos] = useState<{ display: string, value: string, imageType?: string }[]>([]);
  const [spinnerDialogText, setSpinnerDialogText] = useState("");

  const handleChange = (e: (React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>)) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value
    })
  }

  const isFormValid = () => {
    return (values.name.length > 0 && values.description.length > 0 && formHasChanged())
  }

  const formHasChanged = (): boolean => {
    return (values.description !== initialUser.description || values.name !== initialUser.name || values.type !== initialUser.type || values.photoUrl !== initialUser.photoUrl)
  }

  const handleBlur = (e: (React.FocusEvent<HTMLInputElement>)) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value
    })
  }
  const debouncedHandler = debounce(handleChange, 500);

  const handleEditorChange = (newValue: string) => {
    setValues({
      ...values,
      description: newValue
    })
  }

  const loadUser = async () => {
    let header: RawAxiosRequestHeaders = {
      'Content-Type': 'application/json'
    }
    const accessToken = await getAccessTokenSilently();
    header.Authorization = `Bearer ${accessToken}`;
    const userURL = `${process.env.REACT_APP_BACKEND_URL}aboutUsUsers/${id}`;
    const data = await (await axios.get<any>(userURL, {
      headers: header
    })).data
    const aboutUsUser: IAboutUsUser = {
      name: data.aboutUsUser.name,
      description: data.aboutUsUser.description,
      type: data.aboutUsUser.type,
      photoUrl: data.aboutUsUser.photoUrl
    }
    setValues(aboutUsUser)
    setInitialUser(aboutUsUser)

    if (userPhotos.length > 0 && aboutUsUser.photoUrl) {
      if (!userPhotos.some(x => x.value === aboutUsUser.photoUrl)) {
        userPhotos.push({ value: aboutUsUser.photoUrl, display: aboutUsUser.photoUrl })
        setUserPhotos(userPhotos);
      }
    }

    return aboutUsUser
  }

  const getImages = async () => {
    let header: RawAxiosRequestHeaders = {
      'Content-Type': 'application/json'
    }
    const accessToken = await getAccessTokenSilently();
    header.Authorization = `Bearer ${accessToken}`;
    const imageUrl = `${process.env.REACT_APP_BACKEND_URL}images`;
    console.log('imageUrl', imageUrl)
    const data = await (await axios.get<any>(imageUrl, {
      headers: header
    })).data
    console.log('image data', data)
    const mappedImages: { display: string, value: string, imageType?: string }[] =
      data.images.filter((x: IImage) => !x.hidden).map((x: IImage) => {
        return {
          display: x.name,
          value: x.imageUrl,
          imageType: x.imageType
        }
      })

    const userImages = mappedImages.filter(x => x.imageType === 'userPhoto')

    if (values.photoUrl && !userImages.some(x => x.value === values.photoUrl)) {
      userImages.push({ value: values.photoUrl, display: values.photoUrl })
    }
    setUserPhotos(userImages);
    return data;
  }

  const save = async () => {
    const userUrl = `${process.env.REACT_APP_BACKEND_URL}aboutUsUsers/${id}`;
    setSpinnerDialogText("Salvando as mudan&ccedil;as no usu&aacute;rio")
    setSaving(true)
    const accessToken = await getAccessTokenSilently();
    let header: RawAxiosRequestHeaders = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
    const putBody: {
      name: string,
      type: string,
      description: string,
      photoUrl?: string
    } = {
      name: values.name,
      description: values.description,
      type: values.type
    }
    if (values.photoUrl !== undefined) {
      putBody.photoUrl = values.photoUrl
    }
    axios.put(userUrl, putBody, {
      headers: header
    }).then(res => {
      console.log(res)
      queryClient.removeQueries(['editAboutUsUser', id], { exact: true })
      alert('save successful')
    }).catch((err: AxiosError | Error) => {
      if (axios.isAxiosError(err)) {
        console.error(err.response?.data);
      }
      alert('save failed')
    }).finally(() => {
      setSaving(false)
    })
  }

  const deleteUser = async () => {
    const userURL = `${process.env.REACT_APP_BACKEND_URL}aboutUsUsers/${id}`;
    setSpinnerDialogText("Apagando o usuario")
    setSaving(true)
    const accessToken = await getAccessTokenSilently();
    let header: RawAxiosRequestHeaders = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
    axios.delete(userURL, {
      headers: header
    }).then(res => {
      console.log(res)
      queryClient.removeQueries(['editAboutUsUser', id], { exact: true })
      alert('O usuario foi apagado')
      navigate("/Admin/AboutUsUsers")
    }).catch((err: AxiosError | Error) => {
      if (axios.isAxiosError(err)) {
        console.error(err.response?.data);
      }
      alert('erro apagando o usuario')
    }).finally(() => {
      setSaving(false)
    })
  }

  const [userQuery, imagesQuery] = useQueries([
    {
      queryKey: ['editAboutUsUser', id],
      queryFn: loadUser,
      enabled: !auth0Loading,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false
    },
    {
      queryKey: ['images'],
      queryFn: getImages,
      enabled: !auth0Loading
    }
  ])

  if (user && !user['peloestudo/roles'].includes('Owner')) {
    return (<Navigate to="/" />)
  }

  if (userQuery.status === 'loading' || imagesQuery.status === 'loading') {
    return <div><ClipLoader /></div>
  }

  if (userQuery.status === 'error' || imagesQuery.status === 'error') {
    const errors: any[] = [];
    if (userQuery.error) {
      if (axios.isAxiosError(userQuery.error)) {
        if (userQuery.error.response?.status === 404) {
          return (<div>
            User not found.
            <br />
            {returnHome}
          </div>)
        }
      }
      errors.push(userQuery.error)
    }
    if (imagesQuery.status === 'error') {
      errors.push(imagesQuery.error)
    }
    console.error(errors)
    return <div>Error loading User</div>
  }

  if (userQuery.data !== undefined && imagesQuery.data !== undefined) {
    return (
      <section style={pageStyle}>
        <h2>Edita o usu&aacute;rio</h2>
        <div style={gridStyle}>
          <label>Nome do usu&aacute;rio</label>
          <input
            type="text"
            name="name"
            onChange={debouncedHandler}
            onBlur={handleBlur}
            defaultValue={values.name}
          />
          <label>Tipo de usu&aacute;rio</label>
          <select value={values.type} name="type" onChange={handleChange}>
            <option value='Admin'>Administrador</option>
            <option value='Columnist'>Colunista</option>
          </select>
          {
            userPhotos.length > 0 &&
            <>
              <label>Foto do Usu&aacute;rio</label>
              <div>
                {values.photoUrl &&
                  <div style={authorPhotoDivStyle}>
                    <img src={values.photoUrl} alt={`${values.photoUrl}`} style={authorPhotoStyle} />
                  </div>
                }
                <select value={values.photoUrl} name="photoUrl" onChange={handleChange}>
                  <option></option>
                  {userPhotos.map(x => {
                    return <option value={x.value}>{x.display}</option>
                  })}
                </select>
              </div>
            </>
          }
        </div >

        <Editor
          apiKey='c27sqmj1ce4fnrsz5n0bcciljvxysmhsu5mjm3jzxdr24amf'
          onInit={(evt, editor) => editorRef.current = editor}
          onEditorChange={(newValue, editor) => handleEditorChange(newValue)}
          value={values.description}
          init={{
            height: 500,
            plugins: 'preview code',
            toolbar: [
              { name: 'history', items: ['undo', 'redo'] },
              { name: 'styles', items: ['styles'] },
              { name: 'formatting', items: ['bold', 'italic', 'blockquote'] },
              { name: 'alignment', items: ['alignleft', 'aligncenter', 'alignright', 'alignjustify', 'alignnone'] },
              { name: 'indentation', items: ['outdent', 'indent'] },
              { name: 'preview', items: ['preview', 'code'] }
            ]
          }}
        />
        <div style={buttonDiv}>
          <button onClick={save} disabled={!isFormValid() || saving}>Submit</button>
          <button onClick={() => {
            if (window.confirm("Tem certeza que quer apagar? Não vai poder recuperar.")) { deleteUser() }
          }} disabled={saving}>Apagar</button>
        </div>

        {returnHome}
        {
          saving &&
          <SpinnerModal
            spinnerText={spinnerDialogText}
          />
        }
      </section >
    )
  }
  return <></>
}

export default EditAboutUsUserPage;