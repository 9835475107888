import { useQueries, QueryClient } from 'react-query';
import axios, { AxiosError, RawAxiosRequestHeaders } from 'axios';
import { useParams, NavLink, Navigate } from 'react-router-dom';
import { IAdvertisement } from 'Advertisements/IAdvertisement';
import { debounce } from 'lodash';
import { useAuth0 } from '@auth0/auth0-react';
import { ClipLoader } from 'react-spinners';
import { useState } from 'react';
import SpinnerModal from 'Components/SpinnerModal';
import { IImage } from 'Admin/Images/IImage';

const returnHome = (
  <NavLink to="/Admin/Advertisements">
    Propagandas
  </NavLink>
)

const queryClient = new QueryClient()

const pageStyle: React.CSSProperties = {
  textAlign: 'left'
}

const gridStyle: React.CSSProperties = {
  display: 'grid',
  gridTemplateColumns: '200px auto',
  maxWidth: '1000px',
  columnGap: '1em',
  rowGap: '.5em',
  fontSize: '1.5em'
}

const advertisementPhotoDivStyle: React.CSSProperties = {
  maxWidth: '50px'
}

const advertisementPhotoStyle: React.CSSProperties = {
  maxWidth: '100%'
}

function SingleAdvertisementAdminPage() {
  let { id } = useParams()
  const { user, getAccessTokenSilently, isLoading: auth0Loading } = useAuth0();
  const initialValues: IAdvertisement = {
    id: '',
    companyName: '',
    adPhotoURL: '',
    linkURL: ''
  }
  const [values, setValues] = useState(initialValues);
  const [saving, setSaving] = useState(false);
  const [adPhotos, setAdPhotos] = useState<{ display: string, value: string, imageType?: string }[]>([]);
  const [initialAdvertisement, setInitialAdvertisement] = useState(initialValues)
  const [spinnerDialogText, setSpinnerDialogText] = useState("");

  const loadAdvertisement = async () => {
    let header: RawAxiosRequestHeaders = {
      'Content-Type': 'application/json'
    }
    const accessToken = await getAccessTokenSilently();
    header.Authorization = `Bearer ${accessToken}`;
    const advertisementURL = `${process.env.REACT_APP_BACKEND_URL}advertisements/${id}`;
    const data = await (await axios.get<any>(advertisementURL, {
      headers: header
    })).data
    const advertisement: IAdvertisement = {
      id: data.advertisement.id,
      companyName: data.companyName,
      adPhotoURL: data.adPhoto,
      linkURL: data.linkURL

    }
    setValues(advertisement)
    setInitialAdvertisement(advertisement)

    if (adPhotos.length > 0) {
      if (!adPhotos.some(x => x.value === advertisement.adPhotoURL)) {
        adPhotos.push({ value: advertisement.adPhotoURL, display: advertisement.adPhotoURL })
        setAdPhotos(adPhotos);
      }
    }
    return advertisement
  }

  const getImages = async () => {
    let header: RawAxiosRequestHeaders = {
      'Content-Type': 'application/json'
    }
    const accessToken = await getAccessTokenSilently();
    header.Authorization = `Bearer ${accessToken}`;
    const imageUrl = `${process.env.REACT_APP_BACKEND_URL}images`;
    console.log('imageUrl', imageUrl)
    const data = await (await axios.get<any>(imageUrl, {
      headers: header
    })).data
    console.log('image data', data)
    const mappedImages: { display: string, value: string, imageType?: string }[] =
      data.images.filter((x: IImage) => !x.hidden).map((x: IImage) => {
        return {
          display: x.name,
          value: x.imageUrl,
          imageType: x.imageType
        }
      })

    const adPhotos = mappedImages.filter(x => x.imageType === 'adPhoto')
    if (!adPhotos.some(x => x.value === initialAdvertisement.adPhotoURL)) {
      adPhotos.push({ value: initialAdvertisement.adPhotoURL, display: initialAdvertisement.adPhotoURL })
    }
    setAdPhotos(adPhotos);
    return data;
  }

  const handleChange = (e: (React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>)) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value
    })
  }

  const isFormValid = () => {
    return (values.adPhotoURL.length > 0 && values.companyName.length > 0 && values.linkURL.length > 0 && initialAdvertisement !== values);
  }

  const handleBlur = (e: (React.FocusEvent<HTMLInputElement>)) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value
    })
  }

  const debouncedHandler = debounce(handleChange, 500);

  const save = async () => {
    setSpinnerDialogText("Salvando propaganda")
    const advertisementURL = `${process.env.REACT_APP_BACKEND_URL}advertisements/${id}`;
    setSaving(true)
    const accessToken = await getAccessTokenSilently();
    let header: RawAxiosRequestHeaders = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
    const patchBody: IAdvertisement = values;
    axios.patch(advertisementURL, patchBody, {
      headers: header
    }).then(res => {
      console.log(res)
      queryClient.removeQueries(['editAdvertisement', id], { exact: true })
      setInitialAdvertisement(values)
      alert('save successful')
    }).catch((err: AxiosError | Error) => {
      if (axios.isAxiosError(err)) {
        console.error(err.response?.data);
      }
      alert('save failed')
    }).finally(() => {
      setSaving(false)
    })
  }

  const [advertisementQuery, imagesQuery] = useQueries([
    {
      queryKey: ['editAdvertisement', id],
      queryFn: loadAdvertisement,
      enabled: !auth0Loading,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false
    },
    {
      queryKey: ['images'],
      queryFn: getImages,
      enabled: !auth0Loading
    }
  ]
  )
  if (user && !user['peloestudo/roles'].includes('Owner')) {
    return (<Navigate to="/" />)
  }

  if (advertisementQuery.status === 'loading' || imagesQuery.status === 'loading') {
    return <div><ClipLoader /></div>
  }

  if (advertisementQuery.status === 'error' || imagesQuery.status === 'error') {
    const errors: any[] = [];
    if (advertisementQuery.error) {
      if (axios.isAxiosError(advertisementQuery.error)) {
        errors.push(advertisementQuery.error.code)
        if (advertisementQuery.error.response?.status === 404) {
          return (<div>
            Advertisement Not Found.
            <br />
            {returnHome}
          </div>)
        }
      }
      errors.push(advertisementQuery.error)
    }
    if (imagesQuery.status === 'error') {
      errors.push(imagesQuery.error)
    }
    console.error(errors)
    return <div>Error loading Advertisement</div>
  }

  if (advertisementQuery.data !== undefined && imagesQuery.data !== undefined) {
    return (
      <section style={pageStyle}>
        <h2>Administrar Propaganda</h2>
        <div style={gridStyle}>
          <label>Nome da Empresa:
          </label>
          <input
            type="text"
            name="companyName"
            onChange={debouncedHandler}
            onBlur={handleBlur}
            defaultValue={values.companyName}
          />
          {adPhotos.length > 0 &&
            <><label>Foto da Propaganda</label>
              <div>
                {values.adPhotoURL &&
                  <div style={advertisementPhotoDivStyle}>
                    <img src={values.adPhotoURL} alt={`${values.adPhotoURL}`} style={advertisementPhotoStyle} />
                  </div>
                }
                <select value={values.adPhotoURL} name="adPhotoURL" onChange={handleChange}>
                  <option></option>
                  {adPhotos.map(x => {
                    return <option value={x.value}>{x.display}</option>
                  })}
                </select>
              </div></>
          }
          <label>Link da Propaganda</label>
          <input
            type="text"
            name="linkURL"
            onChange={debouncedHandler}
            onBlur={handleBlur}
            defaultValue={values.linkURL}
          />
        </div>
        <div>
          <button onClick={save} disabled={!isFormValid() || saving}>Submit</button>
        </div>

        {returnHome}
        {
          saving &&
          <SpinnerModal
            spinnerText={spinnerDialogText}
          />
        }
      </section >
    )
  }
  return <></>
}

export default SingleAdvertisementAdminPage;