import ReactHtmlParser from 'react-html-parser';
import { IAboutUsUser } from "./IAboutUsUser"

interface displayProps {
  user: IAboutUsUser
}

const userInfoStyle: React.CSSProperties = {
  display: 'flex',
  gap: '50px'
}

const photoDivStyle: React.CSSProperties = {
  maxWidth: '50px'
}

const photoStyle: React.CSSProperties = {
  maxWidth: '100%'
}

const authorStyle: React.CSSProperties = {
  textAlign: 'left',
  marginBlockEnd: '.75em'
}

const AboutUsUserDisplay = ({ user }: displayProps) => {
  return (
    <div style={userInfoStyle}>
      {user.photoUrl &&
        <div style={photoDivStyle}>
          <img src={user.photoUrl} alt={`${user.name}`} style={photoStyle} />
        </div>
      }
      <div>
        <h4 style={authorStyle}>{user.name}</h4>
        <p>{ReactHtmlParser(user.description)}</p>
      </div>
    </div>
  )
}

export default AboutUsUserDisplay