import { useQuery } from 'react-query';
import axios, { RawAxiosRequestHeaders } from 'axios';
import { IBook } from './IBook';
import BookList from './BookList';
import ClipLoader from 'react-spinners/ClipLoader';

const bookURL = `${process.env.REACT_APP_BACKEND_URL}books`;
const BookFeed = () => {
  const loadBooks = async () => {
    let header: RawAxiosRequestHeaders = {
      'Content-Type': 'application/json'
    }
    const data = await (await axios.get<any>(bookURL, {
      headers: header
    })).data
    console.log(data)
    let items: IBook[] = data.data.map(
      (item:
        {
          id: string,
          title: string,
          url: string,
          author: string
        }) => {
        return {
          id: item.id,
          title: item.title,
          url: item.url,
          author: item.author
        }
      });

    console.log(items)

    return items
  }

  const { status, data, error } = useQuery('books', loadBooks)

  if (status === 'loading') {
    return (
      <div>
        <ClipLoader />
      </div>
    )
  }

  if (status === 'error') {
    console.log(error)
    return <div>Error loading Books</div>
  }
  
  if (data !== undefined ) {
    return (
      <BookList items={data}></BookList>
    )
  }

  return <></>

}

export default BookFeed