import { useQuery } from 'react-query';
import axios, { RawAxiosRequestHeaders } from 'axios';
import { IBookReview } from './IBookReview';
import BookReviewList from './BookReviewList';
import BookReviewCarousel from './BookReviewCarousel';
import ClipLoader from 'react-spinners/ClipLoader';
import { FunctionComponent } from 'react';

const bookReviewURL = `${process.env.REACT_APP_BACKEND_URL}bookReviews`;
const BookReviewFeed:FunctionComponent<{carousel?: boolean}> = (props) => {
  const loadBookReviews = async () => {
    let header: RawAxiosRequestHeaders = {
      'Content-Type': 'application/json'
    }
    const data = await (await axios.get<any>(bookReviewURL, {
      headers: header
    })).data
    let items: IBookReview[] = data.data.map(
      (item:
        {
          id: string,
          title: string,
          content: string,
          dateSubmitted: string,
          author: string,
          bookReviewImage?: string,
          header: string
        }) => {
        return  {
          id: item.id,
          title: item.title,
          content: item.content,
          author: item.author,
          dateSubmitted: new Date(item.dateSubmitted),
          header: item.header,
          bookReviewImage: item.bookReviewImage
        } as IBookReview
      });

    return items
  }

  const { status, data, error } = useQuery('bookReviews', loadBookReviews)

  if (status === 'loading') {
    return (
      <div>
        <ClipLoader />
      </div>
    )
  }

  if (status === 'error') {
    console.log(error)
    return <div>Error loading Book Reviews</div>
  }
  
  if (data !== undefined ) {
    if (props.carousel) {
      return (
        <BookReviewCarousel items = {data}></BookReviewCarousel>
      )
    }
    return (
      <BookReviewList items={data}></BookReviewList>
    )
  }

  return <></>

}

export default BookReviewFeed