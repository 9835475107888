import { useQuery } from 'react-query'
import axios, { RawAxiosRequestHeaders } from 'axios';
import { useParams } from 'react-router-dom';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import { IBook } from './IBook';
import { ClipLoader } from 'react-spinners';

function SingleBook() {
  let { id } = useParams()

  const loadBook = async () => {
    let header: RawAxiosRequestHeaders = {
      'Content-Type': 'application/json'
    }
    const bookURL = `${process.env.REACT_APP_BACKEND_URL}books/${id}`;
    const data = await (await axios.get<any>(bookURL, {
      headers: header
    })).data
    const book: IBook = {
      title: data.book.title,
      url: data.book.bookUrl,
      author: data.book.author
    }

    return book
  }

  const { status, data, error } = useQuery('book', loadBook, {
    refetchOnWindowFocus: false,
    retry: (failureCount, error: any) => {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 404) {
          return false;
        }
      }
      return (failureCount < 3)
    }
  })


  if (status === 'loading') {
    return <div><ClipLoader /></div>
  }

  if (status === 'error') {
    if (axios.isAxiosError(error)) {
      console.error('single book is an axios error', error)
      if (error.response?.status === 404) {
        console.error('single book is a 404')
        return <div>
          Book not found.
        </div>
      }
      console.error('single book axios not 404', error.response?.status)
    }
    console.log(error)
    return <div>Error loading Book</div>
  }

  if (data !== undefined) {

    return (
      <>
        <h2>{data.title}</h2>
        <h3>por {data.author}</h3>
        <DocViewer
          documents={[{ uri: data.url }]}
          pluginRenderers={DocViewerRenderers}
          prefetchMethod="GET"
          config={{
            header: {
              disableHeader: false,
              disableFileName: true,
              retainURLParams: false,
            },
          }}
        />

      </>
    )
  }
  return <></>
}

export default SingleBook;