import { useQuery } from 'react-query';
import axios, { RawAxiosRequestHeaders } from 'axios';
import { useParams } from 'react-router-dom';
import { IArticle } from './IArticle';
import Moment from 'react-moment';
import ReactHtmlParser from 'react-html-parser';
import { useAuth0 } from '@auth0/auth0-react';
import { NavLink } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import SpinnerModal from 'Components/SpinnerModal';
import { useState } from 'react';
import { Seo } from 'widgets/Seo';
import SocialMediaShareWidget from 'widgets/SocialMediaShare';
import CommentsSection from 'Comments/CommentsSection';

const returnHome = (
  <NavLink to="/Articles">
    Articles
  </NavLink>
)

const bodyDivStyle: React.CSSProperties = {
  padding: '0 10px'
}

const authorStyle: React.CSSProperties = {
  textAlign: 'left',
  marginBlockEnd: '.75em'
}

const timeStampStyle: React.CSSProperties = {
  fontWeight: 'lighter',
  fontSize: '.75em',
  marginBlockStart: '0'
}

const authorInfoStyle: React.CSSProperties = {
  display: 'flex',
  gap: '50px'
}

const authorPhotoDivStyle: React.CSSProperties = {
  maxWidth: '50px'
}

const authorPhotoStyle: React.CSSProperties = {
  maxWidth: '100%'
}

const headerImageStyle: React.CSSProperties = {
  maxWidth: '750px',
  width: '100%'
}

function SingleArticle() {
  let { id } = useParams()
  const { isAuthenticated, getIdTokenClaims, getAccessTokenSilently, isLoading: auth0Loading } = useAuth0();
  const [saving, setSaving] = useState(false);
  getIdTokenClaims().then(res => {
    console.log('id token claims', res)
  })
  const loadArticle = async () => {
    let header: RawAxiosRequestHeaders = {
      'Content-Type': 'application/json'
    }
    if (isAuthenticated) {
      const accessToken = await getAccessTokenSilently();
      header.Authorization = `Bearer ${accessToken}`;
    }
    const articleURL = `${process.env.REACT_APP_BACKEND_URL}articles/${id}`;
    const data = await (await axios.get<any>(articleURL, {
      headers: header
    })).data
    const article: IArticle = {
      title: data.article.title,
      approved: data.article.approved,
      content: data.article.content,
      dateSubmitted: new Date(data.article.dateSubmitted),
      author: data.article.author,
      header: data.article.header,
      authorPhoto: data.article.authorPhoto,
      articleImage: data.article.articleImage
    }


    return article
  }

  const approveArticle = async () => {
    const accessToken = await getAccessTokenSilently();
    let header: RawAxiosRequestHeaders = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
    setSaving(true);
    const articleURL = `${process.env.REACT_APP_BACKEND_URL}articles/${id}/approve`;
    try {
      const data = await (await axios.post<any>(articleURL, {}, {
        headers: header
      })).data
      console.log(data)
      alert('Ativou o artigo');
    } catch (err) {
      if (axios.isAxiosError(err)) {
        console.error(err.response?.data);
      }
      alert ('erro ativando o artigo')
    }
    setSaving(false);
  }

  const { status, data, error } = useQuery('article', loadArticle, {
    refetchOnWindowFocus: false,
    retry: (failureCount, error: any) => {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 404) {
          return false;
        }
      }
      return (failureCount < 3)
    },
    enabled: !auth0Loading
  })

  if (status === 'loading') {
    return <div><ClipLoader /></div>
  }

  if (status === 'error') {
    if (axios.isAxiosError(error)) {
      console.error('single article is an axios error', error)
      if (error.response?.status === 404) {
        console.error('single article is a 404')
        return <div>
          Article not found.
          <br />
          {returnHome}
        </div>
      }
      console.error('single article axios not 404', error.response?.status)
    }
    console.log(error)
    return <div>Error loading Article</div>
  }

  if (data !== undefined) {
    return (
      <div>
        <Seo 
          title={`${process.env.REACT_APP_SITE_TITLE} | ${data.title}`}
          description={data.header}
          type="Article"
          name={data.author}
          image={data.articleImage}
        />
        {data.articleImage &&
          <div>
            <img src={data.articleImage} alt={data.title} style={headerImageStyle} />
          </div>
        }
        <div>
          <h2>{data.title}</h2>
          <p>{data.header}</p>
          <div style={authorInfoStyle}>
            {data.authorPhoto &&
              <div style={authorPhotoDivStyle}>
                <img src={data.authorPhoto} alt={`${data.author}`} style={authorPhotoStyle} />
              </div>
            }
            <div>
              <h4 style={authorStyle}>{data.author}</h4>
              <p style={timeStampStyle}>
                <Moment locale="pt-br" format='lll'>
                  {data.dateSubmitted}
                </Moment>
              </p>
            </div>
          </div>
          <SocialMediaShareWidget />
        </div>
        <div style={bodyDivStyle}>
          {ReactHtmlParser(data.content)}
        </div>
        {!data.approved &&
          <div><button onClick={() => approveArticle()}>Approve</button></div>
        }
        {
          saving &&
          <SpinnerModal
            spinnerText="Ativando o artigo"
          />
        }
        <CommentsSection articleId={id!} />
        {returnHome}
      </div>
    )
  }
  return <></>
}

export default SingleArticle;