import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon
} from 'react-share';

const iconStyle: React.CSSProperties = {
  display: "flex",
  justifyContent: "center",
  gap: "2%"
}

const SocialMediaShareWidget = () => {
  return (
    <div className="social-share-container">
      <h4 style={{textAlign:"center"}}>Compartilhe</h4>
      <div style={iconStyle}>
        <EmailShareButton url={window.location.href}><EmailIcon /></EmailShareButton>
        <FacebookShareButton url={window.location.href}>
          <FacebookIcon />
        </FacebookShareButton>
        <TwitterShareButton url={window.location.href}><TwitterIcon /></TwitterShareButton>
        <WhatsappShareButton url={window.location.href}><WhatsappIcon /></WhatsappShareButton>
      </div>
    </div>
  )
}

export default SocialMediaShareWidget