import Feed from './Feed';

function YoutubePage() {
  return (
    <>
      <h2>Nosso Canal No Youtube</h2>
      <Feed />
    </>
  )
}

export default YoutubePage;