import ClipLoader from 'react-spinners/ClipLoader';

const modalStyle: React.CSSProperties = {
  zIndex: 1000,
  height: "90%",
  width: "100%",
  opacity: 0.9,
  backgroundColor: "white",
  position: "fixed",
  top: "10%",
  left: "0"
}

const spinnerDivStyle: React.CSSProperties = {
  position: "absolute",
  textAlign: "center",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)"
}

const SpinnerModal = (props: { spinnerText: string }) => {
  return (
    <div style={modalStyle}>
      <div style={spinnerDivStyle}>
        <ClipLoader
          aria-label={props.spinnerText}

        />
        <br />
        <br />
        {props.spinnerText}
      </div>
    </div>
  )
}

export default SpinnerModal;