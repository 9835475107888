import { Editor } from '@tinymce/tinymce-react';
import { Editor as TinyMCEEditor } from 'tinymce';
import React, { useRef, useState } from 'react';
import { debounce } from 'lodash';
import axios, { AxiosError, RawAxiosRequestHeaders } from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import SpinnerModal from 'Components/SpinnerModal';
import { Navigate } from 'react-router-dom';

const pageStyle: React.CSSProperties = {
  textAlign: 'left'
}

const gridStyle: React.CSSProperties = {
  display: 'grid',
  gridTemplateColumns: '200px auto',
  maxWidth: '1000px',
  columnGap: '1em',
  rowGap: '.5em',
  fontSize: '1.5em'
}

function NewArticle() {
  const { user, getAccessTokenSilently } = useAuth0();
  
  const articleURL = `${process.env.REACT_APP_BACKEND_URL}articles`;
  const initialValues = {
    articleTitle: '',
    authorName: user?.name || '',
    content: '',
    header: ''
  }
  const [values, setValues] = useState(initialValues);
  const [saving, setSaving] = useState(false);
  const editorRef = useRef<TinyMCEEditor | null>(null)
  if (user && (!user['peloestudo/roles'].includes('Admin') && !user['peloestudo/roles'].includes('Partner'))) {
    return (<Navigate to="/Articles" />)
  }
  const handleChange = (e: (React.ChangeEvent<HTMLInputElement>| React.ChangeEvent<HTMLTextAreaElement>)) => {
    console.log('handleChange', e)
    setValues({
      ...values,
      [e.target.name]: e.target.value
    })
  }
  const isFormValid = () => {
    console.log('in is form valid')
    return (values.articleTitle?.length > 0 && values.authorName?.length > 0 && values.content?.length > 0 && values.header.length > 0)
  }
  const handleBlur = (e: (React.FocusEvent<HTMLInputElement> | React.FocusEvent<HTMLTextAreaElement>)) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value
    })
  }
  const debouncedHandler = debounce(handleChange, 500);
  const save = async () => {
    setSaving(true)
    const accessToken = await getAccessTokenSilently();
    let header: RawAxiosRequestHeaders = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
    const postBody : {
      author: string,
      content: string,
      title: string,
      header: string,
      authorPhoto?: string
    } = {
      author: values.authorName,
      content: values.content,
      title: values.articleTitle,
      header: values.header
    }
    if (user?.picture !== undefined) {
      postBody.authorPhoto = user.picture
    }
    axios.post(articleURL, postBody , {
      headers: header
    }).then(res => {
      console.log(res)
      alert('save successful')
    }).catch((err: AxiosError | Error) => {
      if (axios.isAxiosError(err)) {
        console.error(err.response?.data);
      }
      alert('save failed')
    }).finally(() => {
      setSaving(false)
    })
  }
  const handleEditorChange = (newValue: string) => {
    setValues({
      ...values,
      content: newValue
    })
  }
  return (
    <section style={pageStyle}>
      <h2>Envie Seu Artigo Aqui</h2>
      <div style={gridStyle}>
        <label>T&iacute;tulo do Artigo:
        </label>
        <input
          type="text"
          name="articleTitle"
          onChange={debouncedHandler}
          onBlur={handleBlur}
          defaultValue={values.articleTitle}
        />
        <label>Colunista:
        </label>
        <input
          type="text"
          name="authorName"
          onChange={debouncedHandler}
          defaultValue={values.authorName}
          onBlur={handleBlur}
          disabled={user?.name?.length !== undefined && user.name.length > 0}
        />
        <label>Cabe&#231;alho:</label>
        <textarea name="header" onBlur={handleBlur} onChange={debouncedHandler} defaultValue={values.header}/>
      </div>

      <Editor
        apiKey='c27sqmj1ce4fnrsz5n0bcciljvxysmhsu5mjm3jzxdr24amf'
        onInit={(evt, editor) => editorRef.current = editor}
        onEditorChange={(newValue, editor) => handleEditorChange(newValue)}
        value={values.content}
        init={{
          height: 500,
          plugins: 'preview code',
          toolbar: [
            { name: 'history', items: ['undo', 'redo'] },
            { name: 'styles', items: ['styles'] },
            { name: 'formatting', items: ['bold', 'italic', 'blockquote'] },
            { name: 'alignment', items: ['alignleft', 'aligncenter', 'alignright', 'alignjustify', 'alignnone'] },
            { name: 'indentation', items: ['outdent', 'indent'] },
            { name: 'preview', items: ['preview', 'code'] }
          ]
        }}
      />
      <button onClick={save} disabled={!isFormValid() || saving}>Submit</button>
      {
        saving &&
        <SpinnerModal
          spinnerText="Obrigado pelo envio do artigo"
        />
      }
    </section>
  );
}

export default NewArticle;