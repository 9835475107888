import { IYoutubeItem } from "./IYoutubeItem"
import { useMediaQuery } from 'react-responsive';

interface YoutubeProps {
  items: IYoutubeItem[]
}

const desktopGridStyle: React.CSSProperties = {
  width: '100%',
  display: 'grid',
  gridTemplateColumns: 'repeat(4,1fr)',
  alignItems: 'start',
  textAlign: 'center',
  rowGap: '2%',
  columnGap: '1%'
}

const mobileGridStyle: React.CSSProperties = {
  width: '100%',
  display: 'flex',
  flexDirection:'column',
  alignItems: 'start',
  textAlign: 'center',
  rowGap: '2%',
}

const itemStyle: React.CSSProperties = {
  display: 'block',
  objectFit: 'cover',
  border: '1px solid lightgray',
  height: '100%',
  width: '100%',
  padding: '5px 2px'
}


const YoutubeGrid = ({items}: YoutubeProps) => {
  const isDesktop = useMediaQuery({
    query: '(min-width: 1224px)'
  })
  if (items.length === 0) {
    return <div></div>
  }
  return (
    <div style={isDesktop? desktopGridStyle : mobileGridStyle}>
      {items.map((item :any)=> {
        return (
          <div style={itemStyle}>
            <iframe allowFullScreen title={item.title} src={`https://www.youtube.com/embed/${item.id}`}></iframe>
            <h5>{item.title}</h5>
          </div>
        )
      })}
    </div>
  )
}

export default YoutubeGrid;